import { useLocation } from "react-router-dom";

export const ROOT = "/";
export const REGISTER = "/register/:inviteToken";
export const PASSWORD_RESET = "/password-reset/:passwordResetToken";
export const LOGIN = "/login/:loginToken?";
export const DASHBOARD = "/dashboard";
export const INVITE = "/invite";
export const ASSIGN = "/assign";
export const USER_MANAGEMENT = "/user-management";
export const PERSONAL_INFO = "/personal-info";
export const NOT_FOUND = "*";
export const WORK_PREFERENCES = "/work-preferences";
export const WORK_AUTHORIZATIONS = "/work-authorizations";
export const JOB_SEARCH_STATUS = "/job-search-status";
export const GOALS = "/goals";
export const WORK_HISTORY = "/work-history";
export const SKILLS = "/skills";
export const ACCOMPLISHMENTS = "/accomplishments";
export const REVIEW = "/review";
export const RESUME_CHECK = "/resume-check";
export const AGREEMENT = "/agreement";
export const ADDITIONAL_QUESTIONS = "/additional-questions";
export const ADMIN_PANEL = "/admin-panel";

export const formRoutes = [
  PERSONAL_INFO,
  WORK_PREFERENCES,
  WORK_AUTHORIZATIONS,
  JOB_SEARCH_STATUS,
  GOALS,
  WORK_HISTORY,
  SKILLS,
  ACCOMPLISHMENTS,
  AGREEMENT,
  REVIEW,
  RESUME_CHECK,
  ADDITIONAL_QUESTIONS,
  LOGIN,
];

export const useIsFormRoute = () => {
  const location = useLocation();
  return formRoutes.includes(location.pathname);
};
