import { useReactiveVar } from "@apollo/client";
import { IconButton, useTheme } from "@material-ui/core";
import BlockTitle from "components/blocktitle/blocktitle";
import Fuse from "fuse.js";
import { Job } from "generated/graphql";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Copy } from "react-feather";
import { useLocation } from "react-router";
import { useCopyToClipboard, usePrevious } from "react-use";
import { openSnackbar, questionSearchTerm } from "reactive.vars";
import { questionSearchRoutes } from "utils/constants";
import { formFieldMapping } from "utils/formFieldMapping";
import { AdditionalQuestion, useForm } from "utils/utils";
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const highlight = (value, indices: any[] = [], i = 1) => {
  const pair = indices[indices.length - i];
  return !pair ? (
    value
  ) : (
    <>
      {highlight(value.substring(0, pair[0]), indices, i + 1)}
      <mark>{value.substring(pair[0], pair[1] + 1)}</mark>
      {value.substring(pair[1] + 1)}
    </>
  );
};

const SearchResult = ({
  title,
  subTitle,
  body,
  matches,
}: {
  title?: string;
  subTitle?: string;
  body?: string;
  matches?: readonly Fuse.FuseResultMatch[] | undefined;
}) => {
  const [, setClipboard] = useCopyToClipboard();

  const theme = useTheme();

  const _matches = matches?.map((match) => ({
    ...match,
    indices: match.indices.filter((ind) => ind[1] - ind[0] > 1),
  }));

  const entries = _matches?.map((match) => [
    match.key,
    highlight(match?.value || "", match?.indices),
  ]) as any[][];

  const hls = Object.fromEntries(entries);

  return (
    <div
      style={{ padding: 12, display: "flex", flexDirection: "column", gap: 6 }}
    >
      {title && (
        <div
          style={{ fontSize: 18, fontWeight: 600, textTransform: "uppercase" }}
        >
          {title}
        </div>
      )}
      {subTitle && (
        <div
          style={{
            color: theme.palette.primary[600],
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {hls.subTitle || subTitle}
        </div>
      )}

      {body && (
        <div
          style={{
            fontSize: 16,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div>{hls.body || body}</div>
          <IconButton
            style={{ marginTop: -12 }}
            onClick={() => {
              setClipboard(body);
              openSnackbar({ message: "Copied to clipboard", type: "success" });
              questionSearchTerm("");
            }}
          >
            <Copy />
          </IconButton>
        </div>
      )}
    </div>
  );
};

const transformAdditionalQuestionField = (
  [, value]: [string, any],
  additionalQuestions:
    | _.Dictionary<(AdditionalQuestion & { job: Job })[]>
    | undefined
) => {
  return Object.entries(value).map(([questionId, answer]) => {
    const question = additionalQuestions?.[questionId.split("_")[0]]?.find(
      (q) => q.hash === questionId.split("_")[1]
    );
    return {
      title:
        question?.job.company && question?.job.title
          ? `${question.job.company} | ${question.job.title}`
          : "",
      subTitle: question?.question_text,
      body: answer,
    };
  });
};

const transformFormField = ([key, value]: [string, any]) => {
  let subTitle: string =
    typeof formFieldMapping[key] === "string"
      ? formFieldMapping[key]
      : formFieldMapping[key]?.label;

  if (!subTitle) {
    const lodashGet = _.get(formFieldMapping, key);
    if (typeof lodashGet === "string") {
      subTitle = lodashGet;
    } else if (lodashGet?.label) {
      subTitle = lodashGet.label;
    }
  }

  if (!value) return [];
  if (Array.isArray(value)) {
    return value.flatMap((v) =>
      transformFormField([
        key,
        _.get(formFieldMapping, `${key}.values.${v}`) || v,
      ])
    );
  } else if (typeof value === "object") {
    const obj = Object.entries(value).flatMap(([k, v]) =>
      transformFormField([`${key}.fields.${k}`, v])
    );

    for (const e of obj) {
      if (subTitle !== e.subTitle) {
        e.title = subTitle;
      }
    }
    return obj;
  }

  const body = typeof value === "string" ? value : null;

  if (body) {
    return [
      {
        subTitle,
        body,
      },
    ];
  } else {
    return [];
  }
};

const fuzzySearch = <T extends any[]>(
  data: T,
  searchTerm: string,
  options?: Fuse.IFuseOptions<any>
) => {
  const fuseOptions = {
    threshold: 0.4,
    includeMatches: true,
    ignoreLocation: true,
    ...options,
  };

  const fuse = new Fuse(data, fuseOptions);
  return fuse.search<T[0]>(searchTerm);
};

const useFormSearch = (searchTerm: string) => {
  const { formFields, additionalQuestions } = useForm();

  const transformedFormFields =
    searchTerm.length >= 3
      ? Object.entries(formFields)
          .flatMap((field) => {
            if (additionalQuestions && field[0] === "aq") {
              return transformAdditionalQuestionField(
                field,
                additionalQuestions
              );
            } else {
              return transformFormField(field);
            }
          })
          .filter(notEmpty)
      : [];

  return fuzzySearch(transformedFormFields, searchTerm, {
    keys: ["title", "subTitle", "body"],
  });
};

export const QuestionSearchOverlay = () => {
  const location = useLocation();
  const route = location.pathname;
  if (!questionSearchRoutes.includes(route)) {
    return null;
  }
  return <QuestionSearchOverlay0 />;
};

const QuestionSearchOverlay0 = () => {
  const theme = useTheme();
  const _questionSearchTerm = useReactiveVar(questionSearchTerm);
  const [restoreScrollY, setRestoreScrollY] = useState<number>();
  const previous = usePrevious(_questionSearchTerm);

  useEffect(() => {
    const element = document.getElementById("question-search-overlay");
    if (!element) return;
    if (_questionSearchTerm.length < 3) {
      element.parentElement!.style.minHeight = "0";

      if (restoreScrollY && previous && previous.length >= 3) {
        window.scrollTo(0, restoreScrollY);
      }
      return;
    }

    element.parentElement!.style.minHeight = element.clientHeight + "px";

    if (!previous || previous.length < 3) {
      setRestoreScrollY(window.scrollY);
      window.scrollTo(0, 0);
    }
  }, [_questionSearchTerm, previous, restoreScrollY]);
  const hits = useFormSearch(_questionSearchTerm);
  if (_questionSearchTerm.length < 3) {
    return (
      <div id="question-search-overlay" style={{ position: "absolute" }}></div>
    );
  }
  return (
    <div
      id="question-search-overlay"
      style={{
        position: "absolute",
        zIndex: 999,
        background: "#fff",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        padding: 32,
        height: "fit-content",
        minHeight: "calc(100% - 64px)",
      }}
    >
      <div>
        <BlockTitle variant="h4">Search results</BlockTitle>
        <div
          style={{
            height: 78,
            background: theme.palette.background.default,
            display: "flex",
            alignItems: "center",
            padding: "0 32px",
            fontSize: 36,
            fontStyle: "italic",
            borderRadius: 10,
            marginTop: 24,
            marginBottom: 32,
          }}
        >
          {_questionSearchTerm}
        </div>
        {hits.map((hit) => (
          <SearchResult
            title={hit.item.title}
            subTitle={hit?.item.subTitle}
            body={hit?.item.body}
            matches={hit.matches}
          />
        ))}
      </div>
    </div>
  );
};
