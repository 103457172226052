import {Issuer} from "generated/graphql";
import _ from "lodash";
import {ADDITIONAL_QUESTIONS} from "router/routePaths";

export const STATES_BY_ABBREVIATION = {
    AK: "Alaska",
    AL: "Alabama",
    AR: "Arkansas",
    AZ: "Arizona",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DC: "Washington, DC",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    IA: "Iowa",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    MA: "Massachusetts",
    MD: "Maryland",
    ME: "Maine",
    MI: "Michigan",
    MN: "Minnesota",
    MO: "Missouri",
    MS: "Mississippi",
    MT: "Montana",
    NC: "North Carolina",
    ND: "North Dakota",
    NE: "Nebraska",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NV: "Nevada",
    NY: "New York",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VA: "Virginia",
    VT: "Vermont",
    WA: "Washington",
    WI: "Wisconsin",
    WV: "West Virginia",
    WY: "Wyoming",
};

export const ABBREVIATIONS_BY_STATE = _.invert(STATES_BY_ABBREVIATION);
export const STATE_NAMES = Object.keys(ABBREVIATIONS_BY_STATE);

export const questionSearchRoutes = [ADDITIONAL_QUESTIONS];
export const DEADLINE_DAYS = 4;
export const DAYS_TO_LOAD = 4;

export const BASE_URL = "/api";

export const getIssuerName = (issuer?: Issuer | null) => {
    const issuers = [Issuer.Hicounselor];

    for (const _issuer of issuers) {
        if (window.location.host.includes(_issuer)) {
            issuer = _issuer;
        }
    }

    switch (issuer) {
        case Issuer.Hicounselor:
            return "HiCounselor JobStep";
        default:
            return "JobStep";
    }
};

export const getIssuer = (issuer?: Issuer | null) => {
    const issuers = [Issuer.Hicounselor];

    for (const _issuer of issuers) {
        if (window.location.host.includes(_issuer)) {
            issuer = _issuer;
        }
    }

    switch (issuer) {
        case Issuer.Hicounselor:
            return "hicounselor";
        default:
            return "jobstep";
    }
};
