/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useRef } from "react";
import { Observable, Subject } from "rxjs";
export type RestrictArray<T> = T extends any[] ? T : [];
export type VoidAsNull<T> = T extends void ? null : T;
export type Not<P, T, F> = P extends false ? T : F;
type ResultBox<T> = { v: T };

export default function useConstant<T>(fn: () => T): T {
  const ref = useRef<ResultBox<T>>();
  if (!ref.current) {
    ref.current = { v: fn() };
  }
  return ref.current.v;
}

export type VoidableEventCallback<EventValue> = EventValue extends void
  ? () => void
  : (e: EventValue) => void;

export type EventCallback<EventValue> = (
  eventSource$: Observable<EventValue>
) => Observable<any>;

export function useEventCallback<EventValue>(
  callback: EventCallback<EventValue>
): VoidableEventCallback<EventValue> {
  const event$ = useConstant(() => new Subject<EventValue>());
  function eventCallback(e: EventValue) {
    return event$.next(e);
  }
  const returnedCallback = useCallback(eventCallback, []);
  useEffect(() => {
    const value$ = (callback as EventCallback<EventValue>)(event$);
    const subscription = value$.subscribe();
    return () => {
      subscription.unsubscribe();
      event$.complete();
    };
  }, []); // immutable forever

  return returnedCallback as VoidableEventCallback<EventValue>;
}
