import { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import "./navigation.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./navigation-style";
import {
  drawerOpened,
  questionSearchTerm,
  validationErrors,
} from "reactive.vars";
import { Drawer, useMediaQuery, useTheme, Paper } from "@material-ui/core";
import { useReactiveVar } from "@apollo/client";
import * as routes from "router/routePaths";

import {
  AlertOctagon,
  Award,
  Briefcase,
  CheckCircle,
  Mail,
  PenTool,
  Star,
  Tool,
  User,
  UserCheck,
  UserPlus,
  Users,
  Settings,
  FileText,
} from "react-feather";
import { Role, useCurrentUserQuery, UserType } from "generated/graphql";
import { Dashboard } from "@material-ui/icons";
import { QuestionSearch } from "components/question-search/question-search";
import { useForm, useFormUser } from "utils/utils";

const pathMap = {
  [routes.PERSONAL_INFO]: {
    outerSelected: "BASIC_INFO",
    innerSelected: "PERSONAL_INFO",
  },
  [routes.WORK_PREFERENCES]: {
    outerSelected: "BASIC_INFO",
    innerSelected: "WORK_PREFERENCES",
  },
  [routes.WORK_AUTHORIZATIONS]: {
    outerSelected: "BASIC_INFO",
    innerSelected: "WORK_AUTHORIZATIONS",
  },
  [routes.JOB_SEARCH_STATUS]: {
    outerSelected: "BASIC_INFO",
    innerSelected: "JOB_SEARCH_STATUS",
  },
  [routes.GOALS]: {
    outerSelected: "GOALS",
  },
  [routes.WORK_HISTORY]: {
    outerSelected: "WORK_HISTORY",
  },
  [routes.SKILLS]: {
    outerSelected: "SKILLS",
  },
  [routes.ACCOMPLISHMENTS]: {
    outerSelected: "ACCOMPLISHMENTS",
  },
  [routes.AGREEMENT]: {
    outerSelected: "AGREEMENT",
  },
  [routes.REVIEW]: {
    outerSelected: "REVIEW",
  },
  [routes.RESUME_CHECK]: {
    outerSelected: "RESUME_CHECK",
  },
  [routes.ADDITIONAL_QUESTIONS]: {
    outerSelected: "ADDITIONAL_QUESTIONS",
  },
  [routes.INVITE]: {
    outerSelected: "INVITE",
  },
  [routes.LOGIN]: {
    outerSelected: "LOGIN",
  },
  [routes.REGISTER]: {
    outerSelected: "REGISTER",
  },
  [routes.DASHBOARD]: {
    outerSelected: "DASHBOARD",
  },
  [routes.ASSIGN]: {
    outerSelected: "ASSIGN",
  },
  [routes.USER_MANAGEMENT]: {
    outerSelected: "USER_MANAGEMENT",
  },
  [routes.ADMIN_PANEL]: {
    outerSelected: "ADMIN_PANEL",
  },
};

const formRoutes = [
  routes.PERSONAL_INFO,
  routes.WORK_PREFERENCES,
  routes.WORK_AUTHORIZATIONS,
  routes.JOB_SEARCH_STATUS,
  routes.GOALS,
  routes.WORK_HISTORY,
  routes.SKILLS,
  routes.ACCOMPLISHMENTS,
  routes.AGREEMENT,
  routes.REVIEW,
  routes.RESUME_CHECK,
  routes.ADDITIONAL_QUESTIONS,
];

const AdditionalQuestionsFragment = ({ outerSelected, handleClick }) => {
  const { additionalQuestions } = useForm();
  if (!additionalQuestions || !Object.keys(additionalQuestions).length) {
    return null;
  } else
    return (
      <div className="nav-menu-fragment">
        <ListItem
          button
          selected={outerSelected === "ADDITIONAL_QUESTIONS"}
          onClick={() => handleClick("ADDITIONAL_QUESTIONS")}
        >
          <ListItemIcon>
            <UserCheck />
          </ListItemIcon>
          <ListItemText primary="Additional Questions" />
        </ListItem>
        <div>
          <QuestionSearch />
        </div>
      </div>
    );
};

export default function UserNavigation({ children }: { children?: any }) {
  const isMobile = useMediaQuery("(max-width: 840px)", { noSsr: true });
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { innerSelected, outerSelected } = pathMap[location.pathname] ?? {};
  const classes = useStyles();
  const _drawerOpened = useReactiveVar(drawerOpened);
  const _validationErrors = useReactiveVar(validationErrors);
  const [open, setOpen] = useState(outerSelected);
  const currentUserQuery = useCurrentUserQuery();
  const formUser = useFormUser();

  const isNormalUser =
    !formUser.loading &&
    (!formUser?.userType || formUser.userType === UserType.Normal);

  const isLiteUser = !formUser.loading && formUser.userType === UserType.Lite;

  const hasResumeScan = formUser.hasResumeScan;

  useEffect(() => setOpen(outerSelected), [outerSelected]);

  if (
    currentUserQuery.error ||
    currentUserQuery.loading ||
    !currentUserQuery.data
  ) {
    return (
      <div className={classes.root + " my-drawer"}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          transitionDuration={0}
          anchor="left"
          open={_drawerOpened}
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{
            style: {
              background: isMobile ? "#FFF" : "transparent",
              transition: "none!important",
            },
          }}
        >
          <Paper
            className={classes.navPaper}
            {...(isMobile && {
              style: { width: "100%", transition: "none!important" },
            })}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.navList}
            >
              <div
                className="nav-menu-fragment"
                style={{
                  height: 620,
                  width: "100%",
                }}
              ></div>
            </List>
          </Paper>
        </Drawer>
      </div>
    );
  }

  const handleClick = (navItem: string) => {
    questionSearchTerm("");
    if (routes[navItem]) {
      history.push({
        pathname: routes[navItem],
        search: history.location.search,
      });
      if (isMobile) {
        drawerOpened(false);
      }
    } else if (navItem === open) {
      setOpen("");
    } else {
      setOpen(navItem);
    }
  };

  const Icon = ({
    routes,
    subRoute,
    icon,
  }: {
    routes: string[];
    subRoute?: boolean;
    icon?: JSX.Element;
  }) => (
    <ListItemIcon
      {...(subRoute && { style: { position: "absolute", left: 26 } })}
    >
      {_validationErrors.some(
        (err) => err.touched && routes.includes(err.route)
      ) ? (
        <AlertOctagon
          {...(subRoute && { style: { width: 15, height: 15 } })}
          color="#FFF"
          fill={theme.palette.primary["A200"]}
        />
      ) : _validationErrors.some((err) => routes.includes(err.route)) ? (
        icon
      ) : (
        <CheckCircle
          {...(subRoute && { style: { width: 15, height: 15 } })}
          color={theme.palette.primary["A100"]}
        />
      )}
    </ListItemIcon>
  );

  return (
    <div className={classes.root + " my-drawer"}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={_drawerOpened}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{
          style: { background: isMobile ? "#FFF" : "transparent" },
        }}
      >
        <Paper
          className={classes.navPaper}
          {...(isMobile && { style: { width: "100%" } })}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.navList}
          >
            {[Role.Admin, Role.Coach].includes(
              currentUserQuery.data.currentUser.role
            ) && (
              <div className="nav-menu-fragment">
                <ListItem
                  button
                  selected={outerSelected === "DASHBOARD"}
                  onClick={() => handleClick("DASHBOARD")}
                >
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText primary="My Assignments" />
                </ListItem>
                {[Role.Admin].includes(
                  currentUserQuery.data.currentUser.role
                ) && (
                  <>
                    <ListItem
                      button
                      selected={outerSelected === "INVITE"}
                      onClick={() => handleClick("INVITE")}
                    >
                      <ListItemIcon>
                        <Mail />
                      </ListItemIcon>
                      <ListItemText primary="Invite" />
                    </ListItem>
                    <ListItem
                      button
                      selected={outerSelected === "ASSIGN"}
                      onClick={() => handleClick("ASSIGN")}
                    >
                      <ListItemIcon>
                        <UserPlus />
                      </ListItemIcon>
                      <ListItemText primary="Assignments" />
                    </ListItem>
                    <ListItem
                      button
                      selected={outerSelected === "USER_MANAGEMENT"}
                      onClick={() => handleClick("USER_MANAGEMENT")}
                    >
                      <ListItemIcon>
                        <Users />
                      </ListItemIcon>
                      <ListItemText primary="User Management" />
                    </ListItem>
                  </>
                )}
                <ListItem
                  button
                  selected={outerSelected === "ADMIN_PANEL"}
                  onClick={() => handleClick("ADMIN_PANEL")}
                >
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary="Admin Panel" />
                </ListItem>
              </div>
            )}

            {formRoutes.includes(location.pathname) && (
              <div>
                <div className="nav-menu-fragment">
                  <ListItem
                    button
                    selected={outerSelected === "BASIC_INFO"}
                    onClick={() => handleClick("BASIC_INFO")}
                  >
                    <Icon
                      routes={[
                        routes.PERSONAL_INFO,
                        routes.WORK_PREFERENCES,
                        routes.WORK_AUTHORIZATIONS,
                        routes.JOB_SEARCH_STATUS,
                      ]}
                      icon={<User />}
                    />
                    <ListItemText primary="Basic Info" />
                    {open === "BASIC_INFO" ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={open === "BASIC_INFO"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        selected={innerSelected === "PERSONAL_INFO"}
                        onClick={() => handleClick("PERSONAL_INFO")}
                      >
                        <Icon routes={[routes.PERSONAL_INFO]} subRoute />
                        <ListItemText primary="Personal Information" />
                      </ListItem>
                      <ListItem
                        button
                        className={classes.nested}
                        selected={innerSelected === "WORK_PREFERENCES"}
                        onClick={() => handleClick("WORK_PREFERENCES")}
                      >
                        <Icon routes={[routes.WORK_PREFERENCES]} subRoute />
                        <ListItemText primary="Work Preferences" />
                      </ListItem>
                      <ListItem
                        button
                        className={classes.nested}
                        selected={innerSelected === "WORK_AUTHORIZATIONS"}
                        onClick={() => handleClick("WORK_AUTHORIZATIONS")}
                      >
                        <Icon routes={[routes.WORK_AUTHORIZATIONS]} subRoute />
                        <ListItemText primary="Work Authorizations" />
                      </ListItem>

                      <ListItem
                        button
                        className={classes.nested}
                        selected={innerSelected === "JOB_SEARCH_STATUS"}
                        onClick={() => handleClick("JOB_SEARCH_STATUS")}
                      >
                        <Icon routes={[routes.JOB_SEARCH_STATUS]} subRoute />
                        <ListItemText primary="Job Search Status" />
                      </ListItem>
                    </List>
                  </Collapse>

                  {isNormalUser && (
                    <>
                      <ListItem
                        button
                        selected={outerSelected === "GOALS"}
                        onClick={() => handleClick("GOALS")}
                      >
                        <Icon routes={[routes.GOALS]} icon={<Star />} />
                        <ListItemText primary="Goals" />
                      </ListItem>
                      <ListItem
                        button
                        selected={outerSelected === "WORK_HISTORY"}
                        onClick={() => handleClick("WORK_HISTORY")}
                      >
                        <Icon
                          routes={[routes.WORK_HISTORY]}
                          icon={<Briefcase />}
                        />
                        <ListItemText primary="Work History" />
                      </ListItem>
                      <ListItem
                        button
                        selected={outerSelected === "SKILLS"}
                        onClick={() => handleClick("SKILLS")}
                      >
                        <Icon routes={[routes.SKILLS]} icon={<Tool />} />
                        <ListItemText primary="Skills" />
                      </ListItem>
                      <ListItem
                        button
                        selected={outerSelected === "ACCOMPLISHMENTS"}
                        onClick={() => handleClick("ACCOMPLISHMENTS")}
                      >
                        <Icon
                          routes={[routes.ACCOMPLISHMENTS]}
                          icon={<Award />}
                        />
                        <ListItemText primary="Accomplishments" />
                      </ListItem>
                    </>
                  )}

                  <ListItem
                    button
                    selected={outerSelected === "AGREEMENT"}
                    onClick={() => handleClick("AGREEMENT")}
                  >
                    <ListItemIcon>
                      <Icon routes={[routes.AGREEMENT]} icon={<PenTool />} />
                    </ListItemIcon>
                    <ListItemText primary="Application Agreement" />
                  </ListItem>
                  <ListItem
                    button
                    selected={outerSelected === "REVIEW"}
                    onClick={() => handleClick("REVIEW")}
                  >
                    <ListItemIcon>
                      <UserCheck />
                    </ListItemIcon>
                    <ListItemText primary="Review" />
                  </ListItem>

                  {hasResumeScan && (
                    <ListItem
                      button
                      selected={outerSelected === "RESUME_CHECK"}
                      onClick={() => handleClick("RESUME_CHECK")}
                    >
                      <ListItemIcon>
                        <FileText />
                      </ListItemIcon>
                      <ListItemText primary="Resume Check" />
                    </ListItem>
                  )}
                </div>
                <AdditionalQuestionsFragment
                  handleClick={handleClick}
                  outerSelected={outerSelected}
                />
              </div>
            )}
          </List>
          {children}
        </Paper>
      </Drawer>
    </div>
  );
}
//!formUser.loading && formUser.form?.submittedAt
