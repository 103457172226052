import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: "none",
      background: "white",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    customBadge: {
      backgroundColor: theme.palette.primary.contrastText,
    },
    title: {
      fontFamily: "Poppins",
      fontSize: 32,
      lineHeight: "40px",
      textTransform: "capitalize",
    },
  })
);

export default useStyles;
