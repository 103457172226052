import { TextField } from "@material-ui/core";
import { useLocation } from "react-router";
import { questionSearchTerm } from "reactive.vars";
import { questionSearchRoutes } from "utils/constants";

export const QuestionSearch = () => {
  const location = useLocation();
  const route = location.pathname;

  if (!questionSearchRoutes.includes(route)) {
    return null;
  }
  return (
    <div style={{ background: "#fff", borderRadius: 0 }}>
      <TextField
        onChange={(e) => {
          questionSearchTerm(e.target.value);
        }}
        style={{ width: "100%" }}
        id="search-answers"
        label="Search answers"
        variant="filled"
        InputProps={{ style: { borderRadius: 0, background: "#fff" } }}
      />
    </div>
  );
};
