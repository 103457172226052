import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const drawerWidth = 300;
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
    },
    navList: {
      padding: 0,
    },
    navPaper: {
      width: 236,
      right: 4,
      position: "absolute",
      top: 144,
    },
    nested: {
      paddingLeft: theme.spacing(6),
      borderLeft: "none !important",
      borderRadius: "0px !important",
    },
    appBar: {},
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      transition: "none",
    },
    drawerPaper: {
      width: drawerWidth,
      border: "none",
      zIndex: 0,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      marginLeft: 0,
    },
  })
);
