import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const drawerWidth = 300;
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      display: "flex",
      paddingTop: 56,
      paddingBottom: 56,
      [theme.breakpoints.down("xs")]: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      marginLeft: 0,
      overflow: "hidden",
    },
    contentShift: {
      [theme.breakpoints.down("xs")]: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      marginLeft: drawerWidth,
    },
  })
);
