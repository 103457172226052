import { Redirect, Route, RouteProps } from "react-router-dom";
import { Role, useCurrentUserQuery } from "generated/graphql";
import { useMediaQuery } from "@material-ui/core";
import { useEffect } from "react";
import { drawerOpened } from "reactive.vars";
import { PagePaper } from "./routes";
interface ProtectedRouteProps extends RouteProps {
  roles?: Role[];
}

export const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
  const { children, ...rest } = props;
  const { data, loading } = useCurrentUserQuery();
  const isMobile = useMediaQuery("(max-width: 840px)", { noSsr: true });

  useEffect(() => {
    drawerOpened(!isMobile);
  }, [isMobile]);

  if (loading) {
    return (
      <div>
        <PagePaper>
          <div
            style={{ textAlign: "center", height: "556px", width: "100%" }}
          ></div>
        </PagePaper>
      </div>
    );
  }

  const currentUser = data ? data.currentUser : null;

  return currentUser &&
    (!props.roles?.length || props.roles.includes(currentUser.role)) ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to={"/login"}></Redirect>
  );
};
