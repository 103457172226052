import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";
import "./index.css";
import "./global.scss";
import jobstepTheming from "./_theming.module.scss";
import hicounselorTheming from "./_theming.hicounselor.module.scss";
import reportWebVitals from "./reportWebVitals";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import App from "App";
import {
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { BrowserRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ColorPartial } from "@material-ui/core/styles/createPalette";
import { createBrowserHistory } from "history";
import { QueryClientProvider, QueryClient } from "react-query";
import { BASE_URL } from "utils/constants";

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn: "https://936ce854128b409ea1a743b9c4631a19@o1008748.ingest.sentry.io/5995320",
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ["error"],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.NODE_ENV,
  });
}

export const cache: InMemoryCache = new InMemoryCache({});
export const history = createBrowserHistory();

const getTheme = (): [{ [key: string]: string }, string] => {
  const host = window.location.host;
  const themes = {
    hicounselor: [hicounselorTheming, "hicounselor-theme"],
  };

  for (const [issuer, theme] of Object.entries(themes)) {
    if (host.includes(issuer)) {
      return theme as [{ [key: string]: string }, string];
    }
  }
  return [jobstepTheming, "jobstep-theme"];
};

const [theme, themeClass] = getTheme();

const brandPalette: ColorPartial = {
  100: theme["P100"],
  200: theme["P200"],
  300: theme["P300"],
  400: theme["P400"],
  500: theme["P500"],
  600: theme["P600"],
  700: theme["P700"],
  800: theme["P800"],
  900: theme["P900"],
  A100: theme["A100"],
  A200: theme["A200"],
};

const MyApolloProvider = (props) => {
  const logoutLink = onError(({ response, graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, extensions }) => {
        if (extensions?.exception?.response?.statusCode === 401) {
          // history.push(LOGIN);
        }
      });
    if (networkError) {
    }
  });

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: ApolloLink.from([
      logoutLink,
      new HttpLink({
        credentials: "include",
        uri: `${BASE_URL}/graphql`,
      }),
    ]),
    cache,
    connectToDevTools: process.env.NODE_ENV === "production" ? false : true,
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

(async () => {
  /*
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });
*/
  document.body.classList.add(themeClass);
  const queryClient = new QueryClient();
  const theme = createMuiTheme({
    palette: {
      background: { default: "#EEEEEE" },
      primary: brandPalette,
      text: { primary: "#333" },
    },
  });
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MyApolloProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </QueryClientProvider>
        </MyApolloProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>,

    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
