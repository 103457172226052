import { validationErrors } from "reactive.vars";
import {
  ACCOMPLISHMENTS,
  AGREEMENT,
  GOALS,
  JOB_SEARCH_STATUS,
  PERSONAL_INFO,
  SKILLS,
  WORK_AUTHORIZATIONS,
  WORK_HISTORY,
  WORK_PREFERENCES,
} from "router/routePaths";
import { from, identity } from "rxjs";
import { concatAll, filter, mergeMap, tap, toArray } from "rxjs/operators";
import * as yup from "yup";
import { Observable } from "rxjs";
import _ from "lodash";
import { isDate } from "./utils";
import { UserType } from "generated/graphql";
const urlPattern =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;

export const validationSchemaLite = [
  {
    route: PERSONAL_INFO,
    schema: yup.object({
      jobHistory: yup
        .array()
        .of(
          yup.object().shape({
            title: yup.string().required("This field is required").nullable(),
            employer: yup
              .string()
              .required("This field is required")
              .nullable(),
          })
        )
        .min(1, "This field is required")
        .required(),
      firstName: yup.string().required("This field is required").nullable(),
      lastName: yup.string().required("This field is required").nullable(),
      dateOfBirth: yup
        .date()
        .typeError("Invalid date")
        .required("This field is required")
        .nullable(),
      personalEmail: yup
        .string()
        .typeError("Invalid Email")
        .email("Invalid Email")
        .required("This field is required")
        .nullable(),
      jobstepEmail: yup
        .string()
        .typeError("Invalid Email")
        .email("Invalid Email")
        .required("This field is required")
        .nullable(),
      phoneNumber: yup
        .string()
        .matches(/^\d{10}$/, "Please enter 10-digit phone number")
        .typeError("Please enter 10-digit phone number")
        .required("This field is required")
        .nullable(),
      streetAddress: yup.string().required("This field is required").nullable(),
      city: yup.string().required("This field is required").nullable(),
      zipCode: yup
        .number()
        .typeError("Must be a 5-digit zip code")
        .required("Must be a 5-digit zip code")
        .nullable(),
      linkedIn: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
      angelist: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
      gitHub: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
      personalWebsite: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
      otherLink: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
    }),
  },
  {
    route: WORK_PREFERENCES,
    schema: yup.object({
      minimumAcceptableSalary: yup
        .number()
        .min(10000, "At least 5 digits")
        .typeError(
          "Please only input number values. Do not include letters or punctuation"
        )
        .required("This field is required"),
      preferredSalaryRangeMin: yup
        .string()
        .required("This field is required")
        .nullable(),
      preferredSalaryRangeMax: yup
        .string()
        .required("This field is required")
        .nullable(),
      employeeBenefits: yup.string().notRequired().nullable(),
      workLocation: yup.array().min(1, "This field is required").required(),
      acceptableCommuteTime: yup
        .string()
        .when("workLocation", (workLocation) =>
          workLocation?.some((location) =>
            ["inPerson", "combination"].includes(location)
          )
            ? yup.string().required("This field is required").nullable()
            : yup.string().nullable()
        )
        .nullable(),

      willingToRelocate: yup
        .string()
        .when("workLocation", (workLocation) =>
          workLocation?.some((location) =>
            ["inPerson", "combination"].includes(location)
          )
            ? yup.string().required("This field is required").nullable()
            : yup.string().nullable()
        )
        .nullable(),
    }),
  },
  {
    route: WORK_AUTHORIZATIONS,
    schema: yup.object({
      workAuthorization: yup
        .string()
        .required("This field is required")
        .nullable(),
      requireSponsorship: yup
        .string()
        .required("This field is required")
        .nullable(),
      workReferences: yup.array().of(
        yup.object().shape({
          email: yup
            .string()
            .email("Please provide a valid email address")
            .required("This field is required")
            .nullable(),

          phoneNumber: yup
            .string()
            .matches(/^\d{10}$/, "Please enter 10-digit phone number")
            .typeError("Please enter 10-digit phone number")
            .nullable(),
        })
      ),
    }),
  },
  {
    route: JOB_SEARCH_STATUS,
    schema: yup.object({
      jobSearchingTime: yup
        .string()
        .required("This field is required")
        .nullable(),
      jobApplicationCount: yup
        .number()
        .typeError("Must be a positive number")
        .min(0, "Must be a positive number")
        .required("This field is required"),
      firstRoundInterviewCount: yup
        .number()
        .typeError("Must be a positive number")
        .min(0, "Must be a positive number")
        .required("This field is required"),
      firstRoundLedToNextRoundCount: yup
        .number()
        .typeError("Must be a positive number")
        .min(0, "Must be a positive number")
        .required("This field is required"),
    }),
  },
  {
    route: AGREEMENT,
    schema: yup.object({
      agreementESignature: yup.string().required("This field is required"),
      agreementEmailAddress: yup
        .string()
        .typeError("Invalid Email")
        .email("Invalid Email")
        .required("This field is required"),
      agreementTodaysDate: yup
        .date()
        .typeError("Please provide today's date")
        .required("Please provide today's date"),
    }),
  },
];

export const validationSchema = [
  {
    route: PERSONAL_INFO,
    schema: yup.object({
      firstName: yup.string().required("This field is required").nullable(),
      lastName: yup.string().required("This field is required").nullable(),
      dateOfBirth: yup
        .date()
        .typeError("Invalid date")
        .required("This field is required")
        .nullable(),
      personalEmail: yup
        .string()
        .typeError("Invalid Email")
        .email("Invalid Email")
        .required("This field is required")
        .nullable(),
      jobstepEmail: yup
        .string()
        .typeError("Invalid Email")
        .email("Invalid Email")
        .required("This field is required")
        .nullable(),
      phoneNumber: yup
        .string()
        .matches(/^\d{10}$/, "Please enter 10-digit phone number")
        .typeError("Please enter 10-digit phone number")
        .required("This field is required")
        .nullable(),
      streetAddress: yup.string().required("This field is required").nullable(),
      city: yup.string().required("This field is required").nullable(),
      zipCode: yup
        .number()
        .typeError("Must be a 5-digit zip code")
        .required("Must be a 5-digit zip code")
        .nullable(),
      linkedIn: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
      angelist: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
      gitHub: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
      personalWebsite: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
      otherLink: yup
        .string()
        .matches(urlPattern, "Please enter a correct url")
        .nullable(),
    }),
  },
  {
    route: WORK_PREFERENCES,
    schema: yup.object({
      minimumAcceptableSalary: yup
        .number()
        .min(10000, "At least 5 digits")
        .typeError(
          "Please only input number values. Do not include letters or punctuation"
        )
        .required("This field is required"),
      preferredSalaryRangeMin: yup
        .string()
        .required("This field is required")
        .nullable(),
      preferredSalaryRangeMax: yup
        .string()
        .required("This field is required")
        .nullable(),
      employeeBenefits: yup.string().notRequired().nullable(),
      workLocation: yup.array().min(1, "This field is required").required(),
      acceptableCommuteTime: yup
        .string()
        .when("workLocation", (workLocation) =>
          workLocation?.some((location) =>
            ["inPerson", "combination"].includes(location)
          )
            ? yup.string().required("This field is required").nullable()
            : yup.string().nullable()
        )
        .nullable(),

      willingToRelocate: yup
        .string()
        .when("workLocation", (workLocation) =>
          workLocation?.some((location) =>
            ["inPerson", "combination"].includes(location)
          )
            ? yup.string().required("This field is required").nullable()
            : yup.string().nullable()
        )
        .nullable(),
    }),
  },
  {
    route: WORK_AUTHORIZATIONS,
    schema: yup.object({
      workAuthorization: yup
        .string()
        .required("This field is required")
        .nullable(),
      requireSponsorship: yup
        .string()
        .required("This field is required")
        .nullable(),
      workReferences: yup.array().of(
        yup.object().shape({
          email: yup
            .string()
            .email("Please provide a valid email address")
            .required("This field is required")
            .nullable(),

          phoneNumber: yup
            .string()
            .matches(/^\d{10}$/, "Please enter 10-digit phone number")
            .typeError("Please enter 10-digit phone number")
            .nullable(),
        })
      ),
    }),
  },
  {
    route: JOB_SEARCH_STATUS,
    schema: yup.object({
      jobSearchingTime: yup
        .string()
        .required("This field is required")
        .nullable(),
      jobApplicationCount: yup
        .number()
        .typeError("Must be a positive number")
        .min(0, "Must be a positive number")
        .required("This field is required"),
      firstRoundInterviewCount: yup
        .number()
        .typeError("Must be a positive number")
        .min(0, "Must be a positive number")
        .required("This field is required"),
      firstRoundLedToNextRoundCount: yup
        .number()
        .typeError("Must be a positive number")
        .min(0, "Must be a positive number")
        .required("This field is required"),
    }),
  },
  {
    route: GOALS,
    schema: yup.object({
      nextJobGoal: yup.string().required("This field is required").nullable(),
      previousJobLiked: yup
        .string()
        .required("This field is required")
        .nullable(),
      nextEmployerValues: yup
        .string()
        .required("This field is required")
        .nullable(),
      careerGoals: yup.string().required("This field is required").nullable(),
      topStrengths: yup.string().required("This field is required").nullable(),
      volunteerActivities: yup
        .string()
        .required("This field is required")
        .nullable(),
    }),
  },
  {
    route: WORK_HISTORY,
    schema: yup.object({
      jobHistory: yup
        .array()
        .of(
          yup.object().shape({
            title: yup.string().required("This field is required").nullable(),
            employer: yup
              .string()
              .required("This field is required")
              .nullable(),
            isRemoteJob: yup.boolean().notRequired().nullable(),
            city: yup
              .string()
              .when("isRemoteJob", (isRemoteJob) => {
                return !isRemoteJob
                  ? yup.string().required("This field is required").nullable()
                  : yup.string().nullable();
              })
              .nullable(),
            state: yup
              .string()
              .when("isRemoteJob", (isRemoteJob) =>
                !isRemoteJob
                  ? yup.string().required("This field is required").nullable()
                  : yup.string().nullable()
              )
              .nullable(),
            isCurrentJob: yup.boolean().notRequired().nullable(),
            promotion: yup.boolean().notRequired().nullable(),
            startingDate: yup
              .date()
              .typeError("Invalid date")
              .required("This field is required")
              .nullable(),
            endingDate: yup
              .date()
              .when("isCurrentJob", (isCurrentJob) =>
                !isCurrentJob
                  ? yup
                      .date()
                      .when("startingDate", (startingDate, schema) =>
                        startingDate && isDate(startingDate)
                          ? schema.min(startingDate, "Invalid ending date")
                          : yup
                              .date()
                              .typeError("Invalid date")
                              .required("This field is required")
                              .nullable()
                      )
                      .required("This field is required")
                      .nullable()
                  : yup.string().nullable()
              )

              .nullable(),
            typicalDay: yup
              .string()
              .required("This field is required")
              .nullable(),
            primaryResponsibilities: yup
              .string()
              .required("This field is required")
              .nullable(),
            doingGoodJob: yup
              .string()
              .required("This field is required")
              .nullable(),
            scopeOfWork: yup
              .string()
              .required("This field is required")
              .nullable(),
            awards: yup.string().nullable(),
            proudestMoments: yup.string().nullable(),
            positiveImpactOnTeam: yup
              .string()
              .required("This field is required")
              .nullable(),
            measureImpact: yup.string().nullable(),
            uniqueAboutYou: yup.string().nullable(),
            reasonForLeavingJob: yup
              .string()
              .when("isCurrentJob", (isCurrentJob) =>
                !isCurrentJob
                  ? yup.string().required("This field is required").nullable()
                  : yup.string().nullable()
              )
              .nullable(),
            softwareTools: yup
              .string()
              .required("This field is required")
              .nullable(),
          })
        )
        .required(""),
    }),
  },
  {
    route: SKILLS,
    schema: yup.object({
      languages: yup
        .array()
        .of(
          yup.object().shape({
            language: yup
              .string()
              .required("This field is required")
              .nullable(),
            profiencyLevel: yup
              .string()
              .required("This field is required")
              .nullable(),
          })
        )
        .required(""),
      technicalSkills: yup
        .array()
        .min(1, "This field is required")
        .required("This field is required"),
      educationHistory: yup
        .array()
        .of(
          yup.object().shape({
            institution: yup
              .string()
              .required("This field is required")
              .nullable(),
            fieldOfStudy: yup
              .string()
              .required("This field is required")
              .nullable(),
            degreeOrCertification: yup
              .string()
              .required("This field is required")
              .nullable(),
            city: yup.string().required("This field is required").nullable(),
            state: yup.string().required("This field is required").nullable(),
            country: yup.string().required("This field is required").nullable(),
            startingDate: yup
              .date()
              .required("This field is required")
              .nullable(),
            completedEducation: yup
              .string()
              .required("This field is required")
              .nullable(),
            endingDate: yup
              .date()
              .required("This field is required")
              .nullable(),
          })
        )
        .required(""),
    }),
  },
  {
    route: ACCOMPLISHMENTS,
    schema: yup.object({
      stories: yup
        .array()
        .of(
          yup.object().shape({
            prompt: yup.string().required("This field is required").nullable(),
            situation: yup
              .string()
              .required("This field is required")
              .nullable(),
            obstacle: yup
              .string()
              .required("This field is required")
              .nullable(),
            action: yup.string().required("This field is required").nullable(),
            result: yup.string().required("This field is required").nullable(),
          })
        )
        .min(5, "A minimum of 5 stories are required")
        .required("A minimum of 5 stories are required"),
    }),
  },
  {
    route: AGREEMENT,
    schema: yup.object({
      agreementESignature: yup.string().required("This field is required"),
      agreementEmailAddress: yup
        .string()
        .typeError("Invalid Email")
        .email("Invalid Email")
        .required("This field is required"),
      agreementTodaysDate: yup
        .date()
        .typeError("Please provide today's date")
        .required("Please provide today's date"),
    }),
  },
];

export const getValidationSchema = (route: string, userType?: UserType) => {
  const schema =
    userType === UserType.Lite ? validationSchemaLite : validationSchema;
  return schema.find((schema) => schema.route === route)?.schema;
};

export const validateWholeForm$ = (
  {
    values,
    touched,
    init,
    dirty,
  }: {
    values: any;
    touched: any;
    init?: boolean;
    dirty?: boolean;
  },
  _validationSchema: any
): Observable<
  { message: string; path: string; route: string; touched: boolean }[]
> =>
  from(_validationSchema).pipe(
    mergeMap<any, any>(({ route, schema }) =>
      schema
        .validate(values, { abortEarly: false })
        .then((_) => null)
        .catch(({ inner }) =>
          inner.map(({ path, message }) => {
            return {
              route,
              path,
              message,
              touched:
                _.get(touched, path) ||
                _.get(values, path) ||
                validationErrors().find((err) => err.path === path)?.touched,
              dirty,
            };
          })
        )
    ),
    filter<any>(identity),
    concatAll<any>(),
    toArray(),
    tap((errors) => validationErrors(errors))
  );

export const validateWholeForm = (formFields, schema) => {
  validateWholeForm$(
    {
      values: formFields,
      touched: {},
      //init: true,
    },
    schema
  ).subscribe((errors: any[]) => validationErrors(errors));
};
