import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import clsx from "clsx";
import useStyles from "./bar-style";
import { drawerOpened } from "reactive.vars";
import { useReactiveVar } from "@apollo/client";
import Slide from "@material-ui/core/Slide";
import "./bar.scss";
import {
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery,
  useScrollTrigger,
} from "@material-ui/core";
import { useCurrentUserQuery, useLogoutMutation } from "generated/graphql";
import { useState } from "react";
import { LogOut } from "react-feather";
import { useHistory } from "react-router";
import { LOGIN } from "router/routePaths";
interface Props {
  children: React.ReactElement;
}
function HideOnScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Bar() {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width: 840px)", { noSsr: true });

  const [anchorEl, setAnchorEl] = useState(null);

  const { data, loading } = useCurrentUserQuery();

  const [logout, { client }] = useLogoutMutation({
    onCompleted: () => {
      client
        .resetStore()
        .catch((e) => {})
        .then(() => {
          window.location.reload();
        });
    },
  });
  const _drawerOpened = useReactiveVar(drawerOpened);
  const handleDrawerToggle = () => {
    drawerOpened(!_drawerOpened);
  };

  const handleClick = (event) => {
    if (data) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    //return <div></div>;
  }

  return (
    <HideOnScroll>
      <AppBar position="sticky" className={clsx(classes.appBar)}>
        <Toolbar>
          {isMobile && history.location.pathname !== LOGIN && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              {_drawerOpened ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          )}
          <div style={{ height: isMobile ? 56 : 88 }} className="appbar-logo" />

          <div
            style={{
              width: isMobile ? 48 : 70,
              height: isMobile ? 48 : 70,
              border: "2px solid rgb(82 69 223)",
              borderRadius: 50,
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            <div className={classes.title}>
              {data?.currentUser?.email[0] || ""}
            </div>
          </div>
        </Toolbar>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          style={{ marginTop: 6 }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MenuItem
            onClick={() => {
              logout();

              handleClose();
            }}
          >
            <ListItemIcon>
              <LogOut />
            </ListItemIcon>
            <div>Sign Out</div>
          </MenuItem>
        </Menu>
      </AppBar>
    </HideOnScroll>
  );
}
