import {
  DocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  QueryTuple,
  useLazyQuery,
} from "@apollo/client";
import { DateType } from "@date-io/type";
import { FormikContextType, useFormikContext } from "formik";
import {
  FormDocument,
  FormQuery,
  Job,
  useCurrentUserQuery,
  useFormLazyQuery,
  useFormQuery,
  useJobsQuery,
  User,
  UserType,
  useUpsertFormMutation,
  ViewLevel,
} from "generated/graphql";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef } from "react";

import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
  onErrorResumeNext,
} from "rxjs/operators";
import { DEADLINE_DAYS, DAYS_TO_LOAD} from "./constants";
import { validateForm } from "./formik.effect";
import { useEventCallback } from "./obs";
import { useUrlQuery } from "./url";
import {
  validateWholeForm,
  validationSchema,
  validationSchemaLite,
} from "./validation";

type FormFields = {
  [key: string]: string | string[] | number | Date | boolean | null;
};

export type AdditionalQuestionType =
  | "text"
  | "dropdown"
  | "input"
  | "textarea"
  | "radio"
  | "number"
  | "checkbox"
  | "date";

export interface Validation {
  maxlength?: number;
  minlength?: number;
  custom_check?: string;
}

export interface AQMetadata {
  validation: Validation;
  placeholder: string;
  pattern: string;
  // todo: add before_action needed? No? searchapi only?
  // todo: add after_action needed? No? searchapi only?
  [key: string]: any;
}
export interface AdditionalQuestion {
  long_response: boolean;
  page: number;
  question_text: string;
  type: AdditionalQuestionType;
  xpath: string | { [key: string]: string };
  metadata: AQMetadata;
  hash: string;
  value?: string;
}

export const additionalQuestionTypes: AdditionalQuestionType[] = [
  "text",
  "dropdown",
  "input",
  "textarea",
  "radio",
  "number",
  "checkbox",
  "date",
];

export function buildUpdateParams<T extends FormFields>(params: T) {
  return Object.entries(params)
    .filter(([key]) => !["__typename", "id"].includes(key))
    .reduce(
      (acc, curr) => ({ ...acc, [curr[0]]: { set: curr[1] } }),
      {} as any
    );
}

export function buildCreateParams<T extends FormFields>(params: T) {
  return Object.entries(params)
    .filter(([key]) => !["__typename"].includes(key))
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr[0]]: Array.isArray(curr[1]) ? { set: curr[1] } : curr[1],
      }),
      {} as any
    );
}

export function buildUpsertParams<T extends FormFields>({
  id,
  formFields,
}: {
  id: string;
  formFields: T;
}) {
  return {
    variables: {
      where: { id },
      update: buildUpdateParams({
        ...formFields,
        touchedAt: new Date().toISOString(),
      }),
      create: buildCreateParams({
        ...formFields,
        touchedAt: new Date().toISOString(),
        id,
      }),
    },
  };
}

export function getObjectDiff<T extends Object>(obj1: T, obj2: T) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

const upsertChangedFields: {
  [key: string]: (
    formFields: FormFields
  ) => ReturnType<ReturnType<typeof useUpsertFormMutation>[0]>;
} = {};
export const useUpsertChangedFields = ({ id }) => {
  const { data } = useFormQuery({
    variables: { where: { id } },
    fetchPolicy: "cache-only",
  });
  const [upsertForm] = useUpsertFormMutation({
    onError: (error) => {
      console.error("Error upserting form");
      console.error(error);
    },
  });

  useEffect(() => {
    upsertChangedFields[id] = (formFields) => {
      /*
      // Access current form fields state before update if needed
      const currentFormFields = data?.form?.json
        ? JSON.parse(data.form.json)
        : null;

      // -----------------------------------
      */
      return upsertForm(
        buildUpsertParams({
          id,
          formFields,
        })
      );
    };
  }, [data, id, upsertForm]);
  return (formFields: FormFields) => upsertChangedFields[id](formFields);
};

function sanitize(object) {
  if (_.isString(object)) return _sanitizeString(object);
  if (_.isArray(object)) return _sanitizeArray(object);
  if (_.isPlainObject(object)) return _sanitizeObject(object);
  return object;
}

function _sanitizeString(string: string) {
  return _.isEmpty(string) ? null : string.trim().replace(/  +/g, " ");
}

function _sanitizeArray(array) {
  return _.filter(_.map(array, sanitize), _isProvided);
}

function _sanitizeObject(object) {
  return _.pickBy(_.mapValues(object, sanitize), _isProvided);
}

function _isProvided(value) {
  const typeIsNotSupported =
    !_.isNull(value) &&
    !_.isString(value) &&
    !_.isArray(value) &&
    !_.isPlainObject(value);
  return typeIsNotSupported || !_.isEmpty(value);
}

export const useUpsertChangedFieldsCallback = <T extends unknown>({ id }) => {
  const upsertChangedFields = useUpsertChangedFields({
    id,
  });

  const formUser = useFormUser();

  const schema =
    formUser?.userType === UserType.Lite
      ? validationSchemaLite
      : validationSchema;

  return useEventCallback<FormikContextType<T>>((obs) =>
    obs.pipe(
      debounceTime(1000),
      validateForm(schema),
      map((json) => {
        const sanitized = sanitize(json);
        return sanitized;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      mergeMap((json) => upsertChangedFields({ json: JSON.stringify(json) })),
      catchError((error) => {
        console.error(error);
        throw error;
      }),
      onErrorResumeNext()
    )
  );
};

export const useSubmitForm = ({ id }) => {
  const upsertChangedFields = useUpsertChangedFields({
    id,
  });

  return useCallback(
    () => upsertChangedFields({ submittedAt: new Date().toISOString() }),
    [upsertChangedFields]
  );
};

const useCachedLazyQuery = <TData = any, TVariables = OperationVariables>(
  document: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> => {
  const cachedData = useRef<TData | undefined>(undefined);
  const [executeMyQuery, queryResult] = useLazyQuery(document, options);
  useEffect(() => {
    if (queryResult.data) {
      cachedData.current = queryResult.data;
    }
  }, [queryResult.loading, queryResult.data]);

  return [
    executeMyQuery,
    //@ts-ignore
    { ...queryResult, data: queryResult.data || cachedData.current },
  ];
};

export const useFormUser = (params?: { id?: string }) => {
  const currentUserQuery = useCurrentUserQuery();
  const query = useUrlQuery();
  const id =
    params?.id ??
    query.get("formId") ??
    currentUserQuery.data?.currentUser.formId;

  const [fetchForm, formQuery] = useCachedLazyQuery<FormQuery>(FormDocument, {
    variables: { where: { id: id } },
  });

  useEffect(() => {
    if (id) {
      fetchForm();
    }
  }, [fetchForm, id]);

  return {
    form: formQuery.data?.form,
    user: formQuery.data?.form?.User,
    userType: formQuery.data?.form?.User?.userType,
    hasResumeScan: (formQuery.data?.form?.User?.resume_check && formQuery.data?.form?.User?.userType === "LITE") || false,
    loading: formQuery.loading,
  };
};

const getAqForJob = (jobId: number, aq: any) => {
  const result: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(aq)) {
    if (key.includes(String(jobId))) {
      result[key] = value;
    }
  }
  return result;
};

const getAqAnswersSourcesForJob = (jobId: number, answerSource: any) => {
  const result: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(answerSource)) {
    if (key.includes(String(jobId))) {
      result[key] = value;
    }
  }
  return result;
};

let initialValidationDone = false;
export const useForm = (params?: {
  id?: string;
}): ReturnType<typeof useFormLazyQuery>[1] & {
  id?: string;
  formFields: { [key: string]: any };
  viewLevel: ViewLevel;
  handleChange: (e: FormikContextType<unknown>) => void;
  submit: ReturnType<typeof useSubmitForm>;
  submittedAt: Date | null;
  jobs: {
    appliedJobs: (Job & {
      additionalQuestions: AdditionalQuestion[];
      additionalQuestionsObject: any;
      additionalQuestionsAnswerSources: any;
    })[];
    deadlineOverJobs: (Job & {
      additionalQuestions: AdditionalQuestion[];
      additionalQuestionsObject: any;
      additionalQuestionsAnswerSources: any;
    })[];
    interestedJobs: (Job & {
      additionalQuestions: AdditionalQuestion[];
      additionalQuestionsObject: any;
      additionalQuestionsAnswerSources: any;
    })[];
    notInterestedJobs: (Job & {
      additionalQuestions: AdditionalQuestion[];
      additionalQuestionsObject: any;
      additionalQuestionsAnswerSources: any;
    })[];
  };
  additionalQuestions:
    | _.Dictionary<(AdditionalQuestion & { job: Job })[]>
    | undefined;
  user?: Partial<User>;
} => {
  const currentUserQuery = useCurrentUserQuery();
  const query = useUrlQuery();
  const id =
    params?.id ??
    query.get("formId") ??
    currentUserQuery.data?.currentUser.formId;
  // console.log("DAYS_TO_LOAD", DAYS_TO_LOAD);
  const fromDate = moment.utc().subtract(DAYS_TO_LOAD, 'days').startOf('day').toISOString();
  // console.log("from date", fromDate)
  // debugger;
  const jobsQuery = useJobsQuery({
    //variables: { where: { User: { is: { formId: { equals: id } } } } },
    variables: { where: { User: { is: { formId: { equals: id } } }, applicationRequested: {not: {equals: true} }, active: {equals: true}, createdAt: {gte: fromDate} } },
  });

  const formQuery = useFormQuery({
    variables: { where: { id: id } },
    // fetchPolicy: "network-only",
  });

  const viewLevel =
    currentUserQuery.data?.currentUser.formId === id ||
    currentUserQuery.data?.currentUser.Views.filter(
      (view) => view.ViewLevel === ViewLevel.Edit
    ).some((view) => view.Form.id === id)
      ? ViewLevel.Edit
      : ViewLevel.View;

  const formFields = formQuery.data?.form
    ? JSON.parse(formQuery.data.form.json)
    : null;
  const submittedAt = formQuery.data?.form?.submittedAt
    ? new Date(formQuery.data.form.submittedAt)
    : null;

  const parsedJobs =
    jobsQuery.data?.jobs &&
    ([...jobsQuery.data?.jobs]
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map((job) => ({
        ...job,
        additionalQuestionsObject:
          formFields && formFields.aq
            ? getAqForJob(job.jobId, formFields.aq)
            : {},
        additionalQuestionsAnswerSources:
          formFields && formFields.answerSource
            ? getAqAnswersSourcesForJob(job.jobId, formFields.answerSource)
            : {},
        additionalQuestions: (
          JSON.parse(job.additionalQuestions || "[]") as AdditionalQuestion[]
        ).map((aq) => ({
          ...aq,
          value: formFields?.aq
            ? formFields.aq[`${job.jobId}_${aq.hash}`] ?? null
            : null,
        })),
      })) as (Job & {
      additionalQuestions: AdditionalQuestion[];
      additionalQuestionsObject: any;
      additionalQuestionsAnswerSources: any;
    })[]);

  const [appliedJobs, newJobs] = _.partition(
    parsedJobs,
    (job) => job.applicationRequested
  );

  const [activeJobs, notInterestedJobs] = _.partition(
    newJobs,
    (job) => job.active
  );

  const [interestedJobs, deadlineOverJobs] = _.partition(
    activeJobs,
    (job) =>
      new Date(job.createdAt).getTime() + 1000 * 60 * 60 * 24 * DEADLINE_DAYS >
      Date.now()
  );

  const additionalQuestions =
    jobsQuery.data?.jobs &&
    _.groupBy<AdditionalQuestion & { job: Job }>(
      [...jobsQuery.data?.jobs]
        .sort((a, b) => a.createdAt - b.createdAt)
        .flatMap((job) =>
          JSON.parse(job.additionalQuestions || "[]").map((question) => ({
            job,
            ...question,
          }))
        ),
      (question) => question.job.jobId
    );

  const jobs = {
    appliedJobs,
    interestedJobs,
    notInterestedJobs,
    deadlineOverJobs,
  };

  const handleChange = useUpsertChangedFieldsCallback({ id });
  const submit = useSubmitForm({ id });

  useEffect(() => {
    initialValidationDone = false;
  }, []);

  const user = formQuery.data?.form?.User || undefined;
  const schema =
    user?.userType === UserType.Lite ? validationSchemaLite : validationSchema;
  if (!initialValidationDone && formFields) {
    initialValidationDone = true;
    validateWholeForm(formFields, schema);
  }

  return {
    id: formQuery.data?.form?.id,
    formFields,
    viewLevel,
    handleChange,
    additionalQuestions,
    submit,
    submittedAt,
    jobs,
    user,
    ...formQuery,
  };
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const FormikDisabler = ({ disabled }: { disabled?: boolean }) => {
  const { setSubmitting } = useFormikContext();
  useEffect(() => {
    if (disabled) {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }
  }, [disabled, setSubmitting]);
  return null;
};

export function jsDateToLocalISO8601DateString(_date: DateType) {
  if (!_date.isValid()) {
    return null;
  }
  const date = _date.toDate();

  return [
    String(date.getFullYear()),
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1),
  ].join("-");
}

export function dateStringToLocalDate(s: string, rollover = false) {
  if (!s || s.includes("NaN")) return null;
  if (s.includes("Z")) {
    if (!rollover) return s;
    const _moment = moment(s.split("T")[0]);
    return _moment.toDate().toUTCString();
  }
  return moment(s).toDate().toUTCString();
}

export interface SortOpts {
  key?: string;
  dir?: "asc" | "desc";
  order?: number;
  customOrder?: any[];
  ignoreThreshold?: number;
}
export function isDate(dateStr: string) {
  return !isNaN(new Date(dateStr).getDate());
}
export const sortByChain = <T>(toSort: T[], sortOpts: SortOpts[]): T[] => {
  sortOpts.sort((a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0));

  const sorter = (_toSort: T[]): T[] =>
    _toSort.sort((a, b) => {
      const decisionFn = (_sortOpts: SortOpts[]): number =>
        _sortOpts.reduce((acc, opt) => {
          const key = opt.key;
          let aVal = key ? _.get(a, key) : a;
          let bVal = key ? _.get(b, key) : b;
          if (typeof aVal === "string" && isDate(aVal))
            aVal = new Date(aVal).getTime();

          if (typeof bVal === "string" && isDate(bVal))
            bVal = new Date(bVal).getTime();

          const dir = opt.dir === "desc" ? -1 : 1;
          const currSort = opt.customOrder
            ? opt.customOrder.findIndex((value) => value === aVal) ===
                opt.customOrder.findIndex((value) => value === bVal) &&
              _sortOpts.length > 1
              ? decisionFn(_sortOpts.slice(1))
              : (opt.customOrder.findIndex((value) => value === aVal) -
                  opt.customOrder.findIndex((value) => value === bVal)) *
                dir
            : aVal === bVal ||
              (opt.ignoreThreshold &&
                typeof aVal === "number" &&
                typeof bVal === "number" &&
                ((aVal > bVal && aVal - opt.ignoreThreshold < bVal) ||
                  (bVal > aVal && bVal - opt.ignoreThreshold < aVal)))
            ? _sortOpts.length > 1
              ? decisionFn(_sortOpts.slice(1))
              : 0
            : (aVal ? aVal : 0) < (bVal ? bVal : 0)
            ? -dir
            : dir;
          return acc || currSort;
        }, 0);

      return decisionFn(sortOpts);
    });

  return sorter([...toSort]);
};

export const createTopScrollbarAg = (selector: string) => {
  // css class selectors
  const headerSelector = ".ag-header";
  const scrollSelector = ".ag-body-horizontal-scroll";
  const scrollViewportSelector = ".ag-body-horizontal-scroll-viewport";
  const scrollContainerSelector = ".ag-body-horizontal-scroll-container";
  const pinSelector = ".ag-horizontal-left-spacer";

  // get scrollbar elements
  const scrollElement = document.querySelector(`${selector} ${scrollSelector}`);

  const scrollViewportElement: HTMLElement | null = document.querySelector(
    `${selector} ${scrollViewportSelector}`
  );
  const scrollContainerElement = document.querySelector(
    `${selector} ${scrollContainerSelector}`
  );
  const pinElement = document.querySelector(`${selector} ${pinSelector}`);

  if (!scrollElement) {
    return () => {};
  }

  // create scrollbar clones
  const cloneElement = scrollElement.cloneNode(true) as Element;
  const cloneViewportElement = cloneElement.querySelector(
    scrollViewportSelector
  );
  const cloneContainerElement = cloneElement.querySelector(
    scrollContainerSelector
  );
  const clonePinElement = cloneElement.querySelector(pinSelector);

  // insert scrollbar clone
  const headerElement = document.querySelector(`${selector} ${headerSelector}`);

  if (
    !headerElement ||
    !pinElement ||
    !scrollViewportElement ||
    !cloneViewportElement ||
    !cloneContainerElement ||
    !scrollContainerElement ||
    !clonePinElement
  ) {
    return () => {};
  }
  let ignoreScrollEvents = false;
  const syncScroll = (element1: Element, element2: Element) => {
    element1.addEventListener("scroll", (e) => {
      const ignore = ignoreScrollEvents;
      ignoreScrollEvents = false;
      if (ignore) return;

      ignoreScrollEvents = true;
      element2.scrollTo({ left: element1.scrollLeft });
    });
  };

  headerElement.insertAdjacentElement("afterend", cloneElement);

  // add event listeners to keep scroll position synchronized
  syncScroll(scrollViewportElement, cloneViewportElement);
  syncScroll(cloneViewportElement, scrollViewportElement);

  // create a mutation observer to keep scroll size synchronized
  const mutationObserver = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      switch (mutation.target) {
        case scrollElement:
          cloneElement.setAttribute(
            "style",
            scrollElement.getAttribute("style")!
          );
          break;
        case scrollViewportElement:
          cloneViewportElement.setAttribute(
            "style",
            scrollViewportElement.getAttribute("style")!
          );
          break;
        case scrollContainerElement:
          cloneContainerElement.setAttribute(
            "style",
            scrollContainerElement.getAttribute("style")!
          );
          break;
        case pinElement:
          clonePinElement.setAttribute(
            "style",
            pinElement.getAttribute("style")!
          );
          break;
      }
    }
  });

  // start observing the scroll elements for `style` attribute changes
  mutationObserver.observe(scrollElement, {
    attributeFilter: ["style"],
    subtree: true,
  });

  return () => {
    mutationObserver.disconnect();
  };
};
