import { makeVar } from "@apollo/client";
import { AdminPanelData } from "api/rest-api/api";
export const drawerOpened = makeVar(false);
export const questionSearchTerm = makeVar("");
export const validationErrors = makeVar<
  {
    message: string;
    path: string;
    route: string;
    touched: boolean;
    dirty?: boolean;
  }[]
>([]);

export const adminPanelModalState = makeVar<{
  opened: boolean;
  row?: AdminPanelData & { selectableRoles: string[] };
}>({
  opened: false,
  row: undefined,
});

export const snackbarState = makeVar<{
  type?: "success" | "info" | "warning" | "error";
  message?: string;
  open: boolean;
}>({ open: false });

export const closeSnackbar = () =>
  snackbarState({ ...snackbarState(), open: false });

export const openAdminPanelModal = ({
  row,
}: {
  row: AdminPanelData & { selectableRoles: string[] };
}) => adminPanelModalState({ row, opened: true });

export const closeAdminPanelModal = () =>
  adminPanelModalState({ ...adminPanelModalState(), opened: false });

export const openSnackbar = ({
  message,
  type,
}: {
  message: string;
  type: "success" | "info" | "warning" | "error";
}) => snackbarState({ open: true, message, type });
