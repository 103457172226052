import { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import * as routes from "./routePaths";
import {
  CircularProgress,
  LinearProgress,
  Paper,
  PaperProps,
  useMediaQuery,
} from "@material-ui/core";

import { ProtectedRoute } from "./protected.route";

import { Role, useCurrentUserQuery } from "generated/graphql";

const Dashboard = lazy(() => import("pages/dashboard/dashboard"));
const ResumeCheck = lazy(() => import("pages/resume-check/resume-check"));
const Invite = lazy(() => import("pages/invite/invite"));
const Assign = lazy(() => import("pages/assign/assign"));
const PasswordReset = lazy(() => import("pages/password-reset/password-reset"));
const UserManagement = lazy(
  () => import("pages/user-management/user-management")
);
const AdminPanel = lazy(() => import("pages/admin-panel/admin-panel"));
const AdditionalQuestions = lazy(
  () => import("pages/additional-questions/additinal-questions")
);
const PersonalInfo = lazy(() => import("pages/personal-info/personal-info"));
const WorkPreferences = lazy(
  () => import("pages/work-preferences/work-preferences")
);
const WorkAuthorizations = lazy(
  () => import("pages/work-authorizations/work-authorizations")
);
const JobSearchStatus = lazy(
  () => import("pages/job-search-status/job-search-status")
);
const Goals = lazy(() => import("pages/goals/goals"));
const WorkHistory = lazy(() => import("pages/work-history/work-history"));
const Skills = lazy(() => import("pages/skills/skills"));
const Accomplishments = lazy(
  () => import("pages/accomplishments/accomplishments")
);
const Review = lazy(() => import("pages/review/review"));
const Agreement = lazy(() => import("pages/agreement/agreement"));
const Register = lazy(() => import("pages/register/register"));
const Login = lazy(() => import("pages/login/login"));

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export const PagePaper = ({ children, ...props }: PaperProps) => {
  const isMobile = useMediaQuery("(max-width: 840px)", { noSsr: true });
  return (
    <Paper
      {...props}
      style={{
        padding: isMobile ? 12 : 32,
        ...props.style,
      }}
    >
      {children}
    </Paper>
  );
};

const Routes = (): JSX.Element => {
  const { data } = useCurrentUserQuery({ onError: () => {} });
  const user = data ? data.currentUser : null;

  const hasResumeCheck =
    (user && [Role.Admin, Role.Coach].includes(user.role)) ||
    (user?.resume_check && user?.userType === "LITE") ||
    false;

  return (
    <Suspense
      fallback={
        <PagePaper>
          <div
            style={{ textAlign: "center", height: "556px", width: "100%" }}
          ></div>
        </PagePaper>
      }
    >
      <ScrollToTop />
      <Switch>
        <Route exact path={routes.ROOT}>
          {user ? (
            [Role.Admin, Role.Coach].includes(user.role) ? (
              <Redirect to={routes.DASHBOARD}></Redirect>
            ) : (
              <Redirect to={routes.PERSONAL_INFO}></Redirect>
            )
          ) : (
            <Redirect to={"/login"}></Redirect>
          )}
        </Route>

        <ProtectedRoute
          exact
          path={routes.DASHBOARD}
          roles={[Role.Admin, Role.Coach]}
        >
          <PagePaper>
            <Dashboard />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={routes.ADMIN_PANEL}
          roles={[Role.Admin, Role.Coach]}
        >
          <PagePaper>
            <AdminPanel />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.INVITE} roles={[Role.Admin]}>
          <PagePaper>
            <Invite />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.ASSIGN} roles={[Role.Admin]}>
          <PagePaper>
            <Assign />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={routes.USER_MANAGEMENT}
          roles={[Role.Admin]}
        >
          <PagePaper>
            <UserManagement />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.PERSONAL_INFO}>
          <PagePaper>
            <PersonalInfo />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.WORK_PREFERENCES}>
          <PagePaper>
            <WorkPreferences />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.WORK_AUTHORIZATIONS}>
          <PagePaper>
            <WorkAuthorizations />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.JOB_SEARCH_STATUS}>
          <PagePaper>
            <JobSearchStatus />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.GOALS}>
          <PagePaper>
            <Goals />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.WORK_HISTORY}>
          <PagePaper>
            <WorkHistory />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.SKILLS}>
          <PagePaper>
            <Skills />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.ACCOMPLISHMENTS}>
          <PagePaper>
            <Accomplishments />
          </PagePaper>
        </ProtectedRoute>
        <ProtectedRoute exact path={routes.REVIEW}>
          <PagePaper>
            <Review />
          </PagePaper>
        </ProtectedRoute>

        <ProtectedRoute exact path={routes.RESUME_CHECK}>
          <PagePaper>{hasResumeCheck && <ResumeCheck />}</PagePaper>
        </ProtectedRoute>

        <ProtectedRoute exact path={routes.AGREEMENT}>
          <PagePaper>
            <Agreement />
          </PagePaper>
        </ProtectedRoute>
        <Route exact path={routes.REGISTER}>
          <PagePaper>
            <Register />
          </PagePaper>
        </Route>
        <Route path={routes.LOGIN}>
          {user ? (
            <Redirect to={routes.ROOT}></Redirect>
          ) : (
            <PagePaper>
              <Login />
            </PagePaper>
          )}
        </Route>
        <Route exact path={routes.PASSWORD_RESET}>
          <PagePaper>
            <PasswordReset />
          </PagePaper>
        </Route>
        <ProtectedRoute exact path={routes.ADDITIONAL_QUESTIONS}>
          <AdditionalQuestions />
        </ProtectedRoute>
        <Route path={routes.NOT_FOUND}>Not found</Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
