import { useEffect } from "react";
import { useFormikContext, FormikContextType } from "formik";
import { map, mergeMap, pairwise } from "rxjs/operators";
import { forkJoin, Observable, of } from "rxjs";
import _ from "lodash";
import { getObjectDiff } from "./utils";
import { validateWholeForm$ } from "./validation";
import { useEffectOnce, useMount } from "react-use";
import { useUrlQuery } from "./url";
export type FormFields<T> = FormikContextType<T>["values"];
export interface FormikEffectProps<T> {
  onChange: (context: FormikContextType<T>) => void;
}

const AutoScroll = () => {
  const query = useUrlQuery();

  const scrollToField = query.get("scrollTo");

  useMount(() => {
    if (scrollToField) {
      const path = decodeURIComponent(scrollToField);
      const element = document.querySelector(
        `input[name="${path}"],
        textarea[name="${path}"]`
      ) as HTMLInputElement;
      if (element) {
        window.scrollTo({ top: element?.scrollTop });
        element.focus();
      }
    }
  });

  return null;
};

export function validateForm<T>(schema: any) {
  return (source$: Observable<FormikContextType<T>>): Observable<T> =>
    source$.pipe(
      mergeMap((ctx) =>
        forkJoin([validateWholeForm$(ctx, schema), of(ctx.values)])
      ),

      map(([errors, values]) => {
        // Uncomment this to filter out the invalid fields
        /*
      for (const err of errors) {
        delete values[err.path];
      }
      */
        for (const key of Object.keys(values)) {
          if (values[key] === "") {
            values[key] = null;
          }
        }
        return values;
      })
    );
}

export function getChangedFields<T>(
  source$: Observable<T>
): Observable<Partial<T>> {
  return source$.pipe(
    pairwise(),
    map(([prev, curr]) => _.pick<T>(curr, getObjectDiff(prev, curr)))
  );
}

export const FormikChangeListener = <T extends unknown>({
  onChange,
}: FormikEffectProps<T>) => {
  const context = useFormikContext<T>();
  useEffect(() => {
    if (context.dirty || Object.keys(context.touched).length) {
      onChange(context);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.values, onChange]);

  return <AutoScroll />;
};

export const ValidateOnMount = () => {
  const { values, setTouched } = useFormikContext();

  useEffectOnce(() => {
    const touched = {};
    Object.entries(values as any).forEach(([key, value]) => {
      if (value === null || value === undefined || value === "") {
      } else {
        touched[key] = true;
      }
    });
    setTouched(touched, true);
  });

  return null;
};
