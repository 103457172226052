import { useReactiveVar } from "@apollo/client";
import { Snackbar, useMediaQuery, useTheme } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import Bar from "components/bar/bar";
import UserNavigation from "components/navigation/navigation";
import { QuestionSearchOverlay } from "components/question-search/question-search-overlay";
import React, { useEffect } from "react";
import { closeSnackbar, drawerOpened, snackbarState } from "reactive.vars";
import { useIsFormRoute } from "router/routePaths";
import Routes from "router/routes";
import { useStyles } from "./app-stlye";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const App: React.FC = (): JSX.Element => {
  const _drawerOpened = useReactiveVar(drawerOpened);
  const _snackbarState = useReactiveVar(snackbarState);

  const theme = useTheme();
  const classes = useStyles();
  const isFormRoute = useIsFormRoute();

  const isMobile = useMediaQuery("(max-width: 840px)", { noSsr: true });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    closeSnackbar();
  };

  useEffect(() => {
    const listener = (event) => {
      const element = document.activeElement as HTMLInputElement;
      if (element && element.type === "number") {
        element.blur();
      }
    };
    document.addEventListener("wheel", listener);
    return () => {
      document.removeEventListener("wheel", listener);
    };
  }, []);

  return (
    <div
      style={{
        background: theme.palette.background.default,
        minHeight: "100vh",
      }}
    >
      <Bar />
      <div>
        <UserNavigation />

        <div
          className={clsx(classes.content, {
            [classes.contentShift]: _drawerOpened,
          })}
          {...(isMobile && { style: { padding: 0 } })}
        >
          <div
            style={{
              position: "relative",
              width: isMobile ? "100vw" : isFormRoute ? "70%" : "90%",
              margin: isMobile ? 0 : "auto",
            }}
          >
            <QuestionSearchOverlay />
            <Routes />
          </div>
        </div>
      </div>
      <Snackbar
        open={_snackbarState.open}
        autoHideDuration={15000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={_snackbarState.type}>
          {_snackbarState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default App;
