import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";

type Variant = "h4" | "h5" | "h2";
interface BlockTitleProps extends React.HTMLAttributes<HTMLElement> {
  text?: string;
  variant: Variant;
  color?: "primary" | "error";
}

export default function BlockTitle(props: BlockTitleProps) {
  const color = props.color ?? "primary";
  const theme = useTheme();
  if (!props.text && !props.children) {
    return null;
  }
  return (
    <div {..._.omit(props, "text")}>
      {(() => {
        switch (props.variant) {
          case "h2":
            return (
              <div style={{ marginBottom: 16, ...props.style }}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
                    lineHeight: "34px",
                    fontSize: 44,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {props.text}
                  {props.children}
                </Typography>
              </div>
            );

          case "h4":
            return (
              <div style={{ marginBottom: 16, ...props.style }}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
                    lineHeight: "34px",
                    fontSize: 32,
                    fontWeight: 400,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {props.text}
                  {props.children}
                </Typography>
                <div
                  style={{
                    width: 144,
                    height: 8,
                    background:
                      color === "primary"
                        ? theme.palette.primary.main
                        : theme.palette.primary["A200"],
                    marginTop: 16,
                  }}
                ></div>
              </div>
            );

          case "h5":
            return (
              <div
                style={{ marginBottom: 24, marginTop: 48, ...props.style }}
                className="h5"
              >
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
                    lineHeight: "26px",
                    fontSize: 24,
                    fontWeight: 400,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {props.text}
                  {props.children}
                </Typography>
              </div>
            );
          default:
            break;
        }
      })()}
    </div>
  );
}
