import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  form?: Maybe<Form>;
  forms: Array<Form>;
  jobs: Array<Job>;
  nonUniqueInvites: Array<NonUniqueInvite>;
  users: Array<User>;
  views: Array<View>;
  currentUser: User;
};


export type QueryFormArgs = {
  where: FormWhereUniqueInput;
};


export type QueryFormsArgs = {
  where?: Maybe<FormWhereInput>;
  orderBy?: Maybe<Array<FormOrderByWithRelationInput>>;
  cursor?: Maybe<FormWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<FormScalarFieldEnum>>;
};


export type QueryJobsArgs = {
  where?: Maybe<JobWhereInput>;
  orderBy?: Maybe<Array<JobOrderByWithRelationInput>>;
  cursor?: Maybe<JobWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<JobScalarFieldEnum>>;
};


export type QueryNonUniqueInvitesArgs = {
  where?: Maybe<NonUniqueInviteWhereInput>;
  orderBy?: Maybe<Array<NonUniqueInviteOrderByWithRelationInput>>;
  cursor?: Maybe<NonUniqueInviteWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<NonUniqueInviteScalarFieldEnum>>;
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};


export type QueryViewsArgs = {
  where?: Maybe<ViewWhereInput>;
  orderBy?: Maybe<Array<ViewOrderByWithRelationInput>>;
  cursor?: Maybe<ViewWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ViewScalarFieldEnum>>;
};

export type Form = {
  __typename?: 'Form';
  id: Scalars['String'];
  json: Scalars['String'];
  submitted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  submittedAt?: Maybe<Scalars['DateTime']>;
  touchedAt?: Maybe<Scalars['DateTime']>;
  _count?: Maybe<FormCount>;
  Views: Array<View>;
  User?: Maybe<User>;
  json_name?: Maybe<Scalars['String']>;
};


export type FormViewsArgs = {
  where?: Maybe<ViewWhereInput>;
  orderBy?: Maybe<Array<ViewOrderByWithRelationInput>>;
  cursor?: Maybe<ViewWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ViewScalarFieldEnum>>;
};


export type FormCount = {
  __typename?: 'FormCount';
  Views: Scalars['Int'];
};

export type View = {
  __typename?: 'View';
  id: Scalars['String'];
  formId: Scalars['String'];
  userId: Scalars['String'];
  ViewLevel: ViewLevel;
  Form: Form;
  User: User;
};

export enum ViewLevel {
  View = 'VIEW',
  Edit = 'EDIT'
}

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  registeredAt?: Maybe<Scalars['DateTime']>;
  signedInAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  userType: UserType;
  email: Scalars['String'];
  active: Scalars['Boolean'];
  removed: Scalars['Boolean'];
  formId?: Maybe<Scalars['String']>;
  applyId?: Maybe<Scalars['Int']>;
  sheetsUrl?: Maybe<Scalars['String']>;
  sheetsStatusHash?: Maybe<Scalars['String']>;
  fetchingJobs: Scalars['Boolean'];
  fetchingSheets: Scalars['Boolean'];
  issuer?: Maybe<Issuer>;
  generated: Scalars['Boolean'];
  resume_check: Scalars['Boolean'];
  _count?: Maybe<UserCount>;
  Invites: Array<Invite>;
  Views: Array<View>;
  ownForm?: Maybe<Form>;
  Jobs: Array<Job>;
  LoginLink: Array<LoginLink>;
};


export type UserInvitesArgs = {
  where?: Maybe<InviteWhereInput>;
  orderBy?: Maybe<Array<InviteOrderByWithRelationInput>>;
  cursor?: Maybe<InviteWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InviteScalarFieldEnum>>;
};


export type UserViewsArgs = {
  where?: Maybe<ViewWhereInput>;
  orderBy?: Maybe<Array<ViewOrderByWithRelationInput>>;
  cursor?: Maybe<ViewWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ViewScalarFieldEnum>>;
};


export type UserJobsArgs = {
  where?: Maybe<JobWhereInput>;
  orderBy?: Maybe<Array<JobOrderByWithRelationInput>>;
  cursor?: Maybe<JobWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<JobScalarFieldEnum>>;
};


export type UserLoginLinkArgs = {
  where?: Maybe<LoginLinkWhereInput>;
  orderBy?: Maybe<Array<LoginLinkOrderByWithRelationInput>>;
  cursor?: Maybe<LoginLinkWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<LoginLinkScalarFieldEnum>>;
};

export enum Role {
  User = 'USER',
  Coach = 'COACH',
  Admin = 'ADMIN'
}

export enum UserType {
  Normal = 'NORMAL',
  Lite = 'LITE'
}

export enum Issuer {
  Hicounselor = 'hicounselor'
}

export type UserCount = {
  __typename?: 'UserCount';
  Invites: Scalars['Int'];
  Views: Scalars['Int'];
  Jobs: Scalars['Int'];
  LoginLink: Scalars['Int'];
};

export type Invite = {
  __typename?: 'Invite';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  User: User;
};

export type InviteWhereInput = {
  AND?: Maybe<Array<InviteWhereInput>>;
  OR?: Maybe<Array<InviteWhereInput>>;
  NOT?: Maybe<Array<InviteWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expiresAt?: Maybe<DateTimeNullableFilter>;
  User?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  registeredAt?: Maybe<DateTimeNullableFilter>;
  signedInAt?: Maybe<DateTimeNullableFilter>;
  Invites?: Maybe<InviteListRelationFilter>;
  Views?: Maybe<ViewListRelationFilter>;
  role?: Maybe<EnumRoleFilter>;
  userType?: Maybe<EnumUserTypeFilter>;
  email?: Maybe<StringFilter>;
  password?: Maybe<StringNullableFilter>;
  active?: Maybe<BoolFilter>;
  removed?: Maybe<BoolFilter>;
  ownForm?: Maybe<FormRelationFilter>;
  formId?: Maybe<StringNullableFilter>;
  Jobs?: Maybe<JobListRelationFilter>;
  applyId?: Maybe<IntNullableFilter>;
  sheetsUrl?: Maybe<StringNullableFilter>;
  sheetsStatusHash?: Maybe<StringNullableFilter>;
  fetchingJobs?: Maybe<BoolFilter>;
  fetchingSheets?: Maybe<BoolFilter>;
  LoginLink?: Maybe<LoginLinkListRelationFilter>;
  issuer?: Maybe<EnumIssuerNullableFilter>;
  generated?: Maybe<BoolFilter>;
  resume_check?: Maybe<BoolFilter>;
};

export type InviteListRelationFilter = {
  every?: Maybe<InviteWhereInput>;
  some?: Maybe<InviteWhereInput>;
  none?: Maybe<InviteWhereInput>;
};

export type ViewListRelationFilter = {
  every?: Maybe<ViewWhereInput>;
  some?: Maybe<ViewWhereInput>;
  none?: Maybe<ViewWhereInput>;
};

export type ViewWhereInput = {
  AND?: Maybe<Array<ViewWhereInput>>;
  OR?: Maybe<Array<ViewWhereInput>>;
  NOT?: Maybe<Array<ViewWhereInput>>;
  id?: Maybe<StringFilter>;
  Form?: Maybe<FormRelationFilter>;
  formId?: Maybe<StringFilter>;
  User?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  ViewLevel?: Maybe<EnumViewLevelFilter>;
};

export type FormRelationFilter = {
  is?: Maybe<FormWhereInput>;
  isNot?: Maybe<FormWhereInput>;
};

export type FormWhereInput = {
  AND?: Maybe<Array<FormWhereInput>>;
  OR?: Maybe<Array<FormWhereInput>>;
  NOT?: Maybe<Array<FormWhereInput>>;
  id?: Maybe<StringFilter>;
  json?: Maybe<StringFilter>;
  submitted?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  submittedAt?: Maybe<DateTimeNullableFilter>;
  touchedAt?: Maybe<DateTimeNullableFilter>;
  Views?: Maybe<ViewListRelationFilter>;
  User?: Maybe<UserRelationFilter>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type EnumViewLevelFilter = {
  equals?: Maybe<ViewLevel>;
  in?: Maybe<Array<ViewLevel>>;
  notIn?: Maybe<Array<ViewLevel>>;
  not?: Maybe<NestedEnumViewLevelFilter>;
};

export type NestedEnumViewLevelFilter = {
  equals?: Maybe<ViewLevel>;
  in?: Maybe<Array<ViewLevel>>;
  notIn?: Maybe<Array<ViewLevel>>;
  not?: Maybe<NestedEnumViewLevelFilter>;
};

export type EnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type NestedEnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type EnumUserTypeFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeFilter>;
};

export type NestedEnumUserTypeFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type JobListRelationFilter = {
  every?: Maybe<JobWhereInput>;
  some?: Maybe<JobWhereInput>;
  none?: Maybe<JobWhereInput>;
};

export type JobWhereInput = {
  AND?: Maybe<Array<JobWhereInput>>;
  OR?: Maybe<Array<JobWhereInput>>;
  NOT?: Maybe<Array<JobWhereInput>>;
  id?: Maybe<StringFilter>;
  jobId?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  score?: Maybe<FloatNullableFilter>;
  title?: Maybe<StringNullableFilter>;
  company?: Maybe<StringNullableFilter>;
  postedDate?: Maybe<StringNullableFilter>;
  jobDescription?: Maybe<StringNullableFilter>;
  salary?: Maybe<FloatNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  apply_url?: Maybe<StringNullableFilter>;
  additionalQuestions?: Maybe<StringNullableFilter>;
  prefilledAdditionalQuestions?: Maybe<IntFilter>;
  searchOrigin?: Maybe<StringNullableFilter>;
  role?: Maybe<StringNullableFilter>;
  applicationTime?: Maybe<StringNullableFilter>;
  applicationStatus?: Maybe<StringNullableFilter>;
  User?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
  applicationRequested?: Maybe<BoolFilter>;
  active?: Maybe<BoolFilter>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type LoginLinkListRelationFilter = {
  every?: Maybe<LoginLinkWhereInput>;
  some?: Maybe<LoginLinkWhereInput>;
  none?: Maybe<LoginLinkWhereInput>;
};

export type LoginLinkWhereInput = {
  AND?: Maybe<Array<LoginLinkWhereInput>>;
  OR?: Maybe<Array<LoginLinkWhereInput>>;
  NOT?: Maybe<Array<LoginLinkWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expiresAt?: Maybe<DateTimeNullableFilter>;
  User?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type EnumIssuerNullableFilter = {
  equals?: Maybe<Issuer>;
  in?: Maybe<Array<Issuer>>;
  notIn?: Maybe<Array<Issuer>>;
  not?: Maybe<NestedEnumIssuerNullableFilter>;
};

export type NestedEnumIssuerNullableFilter = {
  equals?: Maybe<Issuer>;
  in?: Maybe<Array<Issuer>>;
  notIn?: Maybe<Array<Issuer>>;
  not?: Maybe<NestedEnumIssuerNullableFilter>;
};

export type InviteOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  User?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type UserOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  registeredAt?: Maybe<SortOrder>;
  signedInAt?: Maybe<SortOrder>;
  Invites?: Maybe<InviteOrderByRelationAggregateInput>;
  Views?: Maybe<ViewOrderByRelationAggregateInput>;
  role?: Maybe<SortOrder>;
  userType?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  removed?: Maybe<SortOrder>;
  ownForm?: Maybe<FormOrderByWithRelationInput>;
  formId?: Maybe<SortOrder>;
  Jobs?: Maybe<JobOrderByRelationAggregateInput>;
  applyId?: Maybe<SortOrder>;
  sheetsUrl?: Maybe<SortOrder>;
  sheetsStatusHash?: Maybe<SortOrder>;
  fetchingJobs?: Maybe<SortOrder>;
  fetchingSheets?: Maybe<SortOrder>;
  LoginLink?: Maybe<LoginLinkOrderByRelationAggregateInput>;
  issuer?: Maybe<SortOrder>;
  generated?: Maybe<SortOrder>;
  resume_check?: Maybe<SortOrder>;
};

export type InviteOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ViewOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type FormOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  json?: Maybe<SortOrder>;
  submitted?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  touchedAt?: Maybe<SortOrder>;
  Views?: Maybe<ViewOrderByRelationAggregateInput>;
  User?: Maybe<UserOrderByWithRelationInput>;
};

export type JobOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type LoginLinkOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type InviteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum InviteScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  ExpiresAt = 'expiresAt',
  UserId = 'userId'
}

export type ViewOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  Form?: Maybe<FormOrderByWithRelationInput>;
  formId?: Maybe<SortOrder>;
  User?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  ViewLevel?: Maybe<SortOrder>;
};

export type ViewWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ViewScalarFieldEnum {
  Id = 'id',
  FormId = 'formId',
  UserId = 'userId',
  ViewLevel = 'ViewLevel'
}

export type Job = {
  __typename?: 'Job';
  id: Scalars['String'];
  jobId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  score?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  additionalQuestions?: Maybe<Scalars['String']>;
  prefilledAdditionalQuestions: Scalars['Int'];
  searchOrigin?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  applicationTime?: Maybe<Scalars['String']>;
  applicationStatus?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  applicationRequested: Scalars['Boolean'];
  active: Scalars['Boolean'];
  User?: Maybe<User>;
};

export type JobOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  jobId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  company?: Maybe<SortOrder>;
  postedDate?: Maybe<SortOrder>;
  jobDescription?: Maybe<SortOrder>;
  salary?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  apply_url?: Maybe<SortOrder>;
  additionalQuestions?: Maybe<SortOrder>;
  prefilledAdditionalQuestions?: Maybe<SortOrder>;
  searchOrigin?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  applicationTime?: Maybe<SortOrder>;
  applicationStatus?: Maybe<SortOrder>;
  User?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  applicationRequested?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
};

export type JobWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['Int']>;
};

export enum JobScalarFieldEnum {
  Id = 'id',
  JobId = 'jobId',
  CreatedAt = 'createdAt',
  Score = 'score',
  Title = 'title',
  Company = 'company',
  PostedDate = 'postedDate',
  JobDescription = 'jobDescription',
  Salary = 'salary',
  City = 'city',
  State = 'state',
  ApplyUrl = 'apply_url',
  AdditionalQuestions = 'additionalQuestions',
  PrefilledAdditionalQuestions = 'prefilledAdditionalQuestions',
  SearchOrigin = 'searchOrigin',
  Role = 'role',
  ApplicationTime = 'applicationTime',
  ApplicationStatus = 'applicationStatus',
  UserId = 'userId',
  ApplicationRequested = 'applicationRequested',
  Active = 'active'
}

export type LoginLink = {
  __typename?: 'LoginLink';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  User: User;
};

export type LoginLinkOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  User?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export type LoginLinkWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum LoginLinkScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  ExpiresAt = 'expiresAt',
  UserId = 'userId'
}

export type FormWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum FormScalarFieldEnum {
  Id = 'id',
  Json = 'json',
  Submitted = 'submitted',
  CreatedAt = 'createdAt',
  SubmittedAt = 'submittedAt',
  TouchedAt = 'touchedAt'
}

export type NonUniqueInvite = {
  __typename?: 'NonUniqueInvite';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  assignAdmins: Scalars['Boolean'];
  userType: UserType;
  issuer?: Maybe<Issuer>;
};

export type NonUniqueInviteWhereInput = {
  AND?: Maybe<Array<NonUniqueInviteWhereInput>>;
  OR?: Maybe<Array<NonUniqueInviteWhereInput>>;
  NOT?: Maybe<Array<NonUniqueInviteWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expiresAt?: Maybe<DateTimeNullableFilter>;
  url?: Maybe<StringFilter>;
  assignAdmins?: Maybe<BoolFilter>;
  userType?: Maybe<EnumUserTypeFilter>;
  issuer?: Maybe<EnumIssuerNullableFilter>;
};

export type NonUniqueInviteOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  assignAdmins?: Maybe<SortOrder>;
  userType?: Maybe<SortOrder>;
  issuer?: Maybe<SortOrder>;
};

export type NonUniqueInviteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum NonUniqueInviteScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  ExpiresAt = 'expiresAt',
  Url = 'url',
  AssignAdmins = 'assignAdmins',
  UserType = 'userType',
  Issuer = 'issuer'
}

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['String']>;
  applyId?: Maybe<Scalars['Int']>;
};

export enum UserScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  RegisteredAt = 'registeredAt',
  SignedInAt = 'signedInAt',
  Role = 'role',
  UserType = 'userType',
  Email = 'email',
  Password = 'password',
  Active = 'active',
  Removed = 'removed',
  FormId = 'formId',
  ApplyId = 'applyId',
  SheetsUrl = 'sheetsUrl',
  SheetsStatusHash = 'sheetsStatusHash',
  FetchingJobs = 'fetchingJobs',
  FetchingSheets = 'fetchingSheets',
  Issuer = 'issuer',
  Generated = 'generated',
  ResumeCheck = 'resume_check'
}

export type Mutation = {
  __typename?: 'Mutation';
  upsertForm: Form;
  createInvite: Invite;
  upsertInvite: Invite;
  updateJob?: Maybe<Job>;
  upsertJob: Job;
  createNonUniqueInvite: NonUniqueInvite;
  deleteManyNonUniqueInvite: AffectedRowsOutput;
  updateUser?: Maybe<User>;
  deleteManyView: AffectedRowsOutput;
  upsertView: View;
  register: User;
  loginWithToken: User;
  login: User;
  passwordReset: Scalars['String'];
  changePasswordWithToken: User;
  logout: Scalars['String'];
  apply: Job;
};


export type MutationUpsertFormArgs = {
  where: FormWhereUniqueInput;
  create: FormCreateInput;
  update: FormUpdateInput;
};


export type MutationCreateInviteArgs = {
  data: InviteCreateInput;
};


export type MutationUpsertInviteArgs = {
  where: InviteWhereUniqueInput;
  create: InviteCreateInput;
  update: InviteUpdateInput;
};


export type MutationUpdateJobArgs = {
  data: JobUpdateInput;
  where: JobWhereUniqueInput;
};


export type MutationUpsertJobArgs = {
  where: JobWhereUniqueInput;
  create: JobCreateInput;
  update: JobUpdateInput;
};


export type MutationCreateNonUniqueInviteArgs = {
  data: NonUniqueInviteCreateInput;
};


export type MutationDeleteManyNonUniqueInviteArgs = {
  where?: Maybe<NonUniqueInviteWhereInput>;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationDeleteManyViewArgs = {
  where?: Maybe<ViewWhereInput>;
};


export type MutationUpsertViewArgs = {
  where: ViewWhereUniqueInput;
  create: ViewCreateInput;
  update: ViewUpdateInput;
};


export type MutationRegisterArgs = {
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  inviteToken: Scalars['String'];
};


export type MutationLoginWithTokenArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationChangePasswordWithTokenArgs = {
  password: Scalars['String'];
  passwordResetToken: Scalars['String'];
};


export type MutationApplyArgs = {
  answers: Scalars['String'];
  jobId: Scalars['Int'];
};

export type FormCreateInput = {
  id?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  touchedAt?: Maybe<Scalars['DateTime']>;
  Views?: Maybe<ViewCreateNestedManyWithoutFormInput>;
  User?: Maybe<UserCreateNestedOneWithoutOwnFormInput>;
};

export type ViewCreateNestedManyWithoutFormInput = {
  create?: Maybe<Array<ViewCreateWithoutFormInput>>;
  connectOrCreate?: Maybe<Array<ViewCreateOrConnectWithoutFormInput>>;
  createMany?: Maybe<ViewCreateManyFormInputEnvelope>;
  connect?: Maybe<Array<ViewWhereUniqueInput>>;
};

export type ViewCreateWithoutFormInput = {
  id?: Maybe<Scalars['String']>;
  User: UserCreateNestedOneWithoutViewsInput;
  ViewLevel?: Maybe<ViewLevel>;
};

export type UserCreateNestedOneWithoutViewsInput = {
  create?: Maybe<UserCreateWithoutViewsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutViewsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutViewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  signedInAt?: Maybe<Scalars['DateTime']>;
  Invites?: Maybe<InviteCreateNestedManyWithoutUserInput>;
  role?: Maybe<Role>;
  userType?: Maybe<UserType>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  removed?: Maybe<Scalars['Boolean']>;
  ownForm?: Maybe<FormCreateNestedOneWithoutUserInput>;
  Jobs?: Maybe<JobCreateNestedManyWithoutUserInput>;
  applyId?: Maybe<Scalars['Int']>;
  sheetsUrl?: Maybe<Scalars['String']>;
  sheetsStatusHash?: Maybe<Scalars['String']>;
  fetchingJobs?: Maybe<Scalars['Boolean']>;
  fetchingSheets?: Maybe<Scalars['Boolean']>;
  LoginLink?: Maybe<LoginLinkCreateNestedManyWithoutUserInput>;
  issuer?: Maybe<Issuer>;
  generated?: Maybe<Scalars['Boolean']>;
  resume_check?: Maybe<Scalars['Boolean']>;
};

export type InviteCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<InviteCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<InviteCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<InviteCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<InviteWhereUniqueInput>>;
};

export type InviteCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type InviteCreateOrConnectWithoutUserInput = {
  where: InviteWhereUniqueInput;
  create: InviteCreateWithoutUserInput;
};

export type InviteCreateManyUserInputEnvelope = {
  data: Array<InviteCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InviteCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type FormCreateNestedOneWithoutUserInput = {
  create?: Maybe<FormCreateWithoutUserInput>;
  connectOrCreate?: Maybe<FormCreateOrConnectWithoutUserInput>;
  connect?: Maybe<FormWhereUniqueInput>;
};

export type FormCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  touchedAt?: Maybe<Scalars['DateTime']>;
  Views?: Maybe<ViewCreateNestedManyWithoutFormInput>;
};

export type FormCreateOrConnectWithoutUserInput = {
  where: FormWhereUniqueInput;
  create: FormCreateWithoutUserInput;
};

export type JobCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<JobCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<JobCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<JobCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<JobWhereUniqueInput>>;
};

export type JobCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  jobId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  additionalQuestions?: Maybe<Scalars['String']>;
  prefilledAdditionalQuestions?: Maybe<Scalars['Int']>;
  searchOrigin?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  applicationTime?: Maybe<Scalars['String']>;
  applicationStatus?: Maybe<Scalars['String']>;
  applicationRequested?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type JobCreateOrConnectWithoutUserInput = {
  where: JobWhereUniqueInput;
  create: JobCreateWithoutUserInput;
};

export type JobCreateManyUserInputEnvelope = {
  data: Array<JobCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type JobCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  jobId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  additionalQuestions?: Maybe<Scalars['String']>;
  prefilledAdditionalQuestions?: Maybe<Scalars['Int']>;
  searchOrigin?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  applicationTime?: Maybe<Scalars['String']>;
  applicationStatus?: Maybe<Scalars['String']>;
  applicationRequested?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type LoginLinkCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<LoginLinkCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<LoginLinkCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<LoginLinkCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<LoginLinkWhereUniqueInput>>;
};

export type LoginLinkCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type LoginLinkCreateOrConnectWithoutUserInput = {
  where: LoginLinkWhereUniqueInput;
  create: LoginLinkCreateWithoutUserInput;
};

export type LoginLinkCreateManyUserInputEnvelope = {
  data: Array<LoginLinkCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoginLinkCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreateOrConnectWithoutViewsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutViewsInput;
};

export type ViewCreateOrConnectWithoutFormInput = {
  where: ViewWhereUniqueInput;
  create: ViewCreateWithoutFormInput;
};

export type ViewCreateManyFormInputEnvelope = {
  data: Array<ViewCreateManyFormInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ViewCreateManyFormInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  ViewLevel?: Maybe<ViewLevel>;
};

export type UserCreateNestedOneWithoutOwnFormInput = {
  create?: Maybe<UserCreateWithoutOwnFormInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnFormInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutOwnFormInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  signedInAt?: Maybe<Scalars['DateTime']>;
  Invites?: Maybe<InviteCreateNestedManyWithoutUserInput>;
  Views?: Maybe<ViewCreateNestedManyWithoutUserInput>;
  role?: Maybe<Role>;
  userType?: Maybe<UserType>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  removed?: Maybe<Scalars['Boolean']>;
  Jobs?: Maybe<JobCreateNestedManyWithoutUserInput>;
  applyId?: Maybe<Scalars['Int']>;
  sheetsUrl?: Maybe<Scalars['String']>;
  sheetsStatusHash?: Maybe<Scalars['String']>;
  fetchingJobs?: Maybe<Scalars['Boolean']>;
  fetchingSheets?: Maybe<Scalars['Boolean']>;
  LoginLink?: Maybe<LoginLinkCreateNestedManyWithoutUserInput>;
  issuer?: Maybe<Issuer>;
  generated?: Maybe<Scalars['Boolean']>;
  resume_check?: Maybe<Scalars['Boolean']>;
};

export type ViewCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ViewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ViewCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ViewCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ViewWhereUniqueInput>>;
};

export type ViewCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  Form: FormCreateNestedOneWithoutViewsInput;
  ViewLevel?: Maybe<ViewLevel>;
};

export type FormCreateNestedOneWithoutViewsInput = {
  create?: Maybe<FormCreateWithoutViewsInput>;
  connectOrCreate?: Maybe<FormCreateOrConnectWithoutViewsInput>;
  connect?: Maybe<FormWhereUniqueInput>;
};

export type FormCreateWithoutViewsInput = {
  id?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  touchedAt?: Maybe<Scalars['DateTime']>;
  User?: Maybe<UserCreateNestedOneWithoutOwnFormInput>;
};

export type FormCreateOrConnectWithoutViewsInput = {
  where: FormWhereUniqueInput;
  create: FormCreateWithoutViewsInput;
};

export type ViewCreateOrConnectWithoutUserInput = {
  where: ViewWhereUniqueInput;
  create: ViewCreateWithoutUserInput;
};

export type ViewCreateManyUserInputEnvelope = {
  data: Array<ViewCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ViewCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  ViewLevel?: Maybe<ViewLevel>;
};

export type UserCreateOrConnectWithoutOwnFormInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutOwnFormInput;
};

export type FormUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  json?: Maybe<StringFieldUpdateOperationsInput>;
  submitted?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submittedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  touchedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  Views?: Maybe<ViewUpdateManyWithoutFormInput>;
  User?: Maybe<UserUpdateOneWithoutOwnFormInput>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type ViewUpdateManyWithoutFormInput = {
  create?: Maybe<Array<ViewCreateWithoutFormInput>>;
  connectOrCreate?: Maybe<Array<ViewCreateOrConnectWithoutFormInput>>;
  upsert?: Maybe<Array<ViewUpsertWithWhereUniqueWithoutFormInput>>;
  createMany?: Maybe<ViewCreateManyFormInputEnvelope>;
  set?: Maybe<Array<ViewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ViewWhereUniqueInput>>;
  delete?: Maybe<Array<ViewWhereUniqueInput>>;
  connect?: Maybe<Array<ViewWhereUniqueInput>>;
  update?: Maybe<Array<ViewUpdateWithWhereUniqueWithoutFormInput>>;
  updateMany?: Maybe<Array<ViewUpdateManyWithWhereWithoutFormInput>>;
  deleteMany?: Maybe<Array<ViewScalarWhereInput>>;
};

export type ViewUpsertWithWhereUniqueWithoutFormInput = {
  where: ViewWhereUniqueInput;
  update: ViewUpdateWithoutFormInput;
  create: ViewCreateWithoutFormInput;
};

export type ViewUpdateWithoutFormInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  User?: Maybe<UserUpdateOneRequiredWithoutViewsInput>;
  ViewLevel?: Maybe<EnumViewLevelFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutViewsInput = {
  create?: Maybe<UserCreateWithoutViewsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutViewsInput>;
  upsert?: Maybe<UserUpsertWithoutViewsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutViewsInput>;
};

export type UserUpsertWithoutViewsInput = {
  update: UserUpdateWithoutViewsInput;
  create: UserCreateWithoutViewsInput;
};

export type UserUpdateWithoutViewsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  registeredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  signedInAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  Invites?: Maybe<InviteUpdateManyWithoutUserInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  userType?: Maybe<EnumUserTypeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  removed?: Maybe<BoolFieldUpdateOperationsInput>;
  ownForm?: Maybe<FormUpdateOneWithoutUserInput>;
  Jobs?: Maybe<JobUpdateManyWithoutUserInput>;
  applyId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sheetsUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sheetsStatusHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fetchingJobs?: Maybe<BoolFieldUpdateOperationsInput>;
  fetchingSheets?: Maybe<BoolFieldUpdateOperationsInput>;
  LoginLink?: Maybe<LoginLinkUpdateManyWithoutUserInput>;
  issuer?: Maybe<NullableEnumIssuerFieldUpdateOperationsInput>;
  generated?: Maybe<BoolFieldUpdateOperationsInput>;
  resume_check?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type InviteUpdateManyWithoutUserInput = {
  create?: Maybe<Array<InviteCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<InviteCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<InviteUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<InviteCreateManyUserInputEnvelope>;
  set?: Maybe<Array<InviteWhereUniqueInput>>;
  disconnect?: Maybe<Array<InviteWhereUniqueInput>>;
  delete?: Maybe<Array<InviteWhereUniqueInput>>;
  connect?: Maybe<Array<InviteWhereUniqueInput>>;
  update?: Maybe<Array<InviteUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<InviteUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<InviteScalarWhereInput>>;
};

export type InviteUpsertWithWhereUniqueWithoutUserInput = {
  where: InviteWhereUniqueInput;
  update: InviteUpdateWithoutUserInput;
  create: InviteCreateWithoutUserInput;
};

export type InviteUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type InviteUpdateWithWhereUniqueWithoutUserInput = {
  where: InviteWhereUniqueInput;
  data: InviteUpdateWithoutUserInput;
};

export type InviteUpdateManyWithWhereWithoutUserInput = {
  where: InviteScalarWhereInput;
  data: InviteUpdateManyMutationInput;
};

export type InviteScalarWhereInput = {
  AND?: Maybe<Array<InviteScalarWhereInput>>;
  OR?: Maybe<Array<InviteScalarWhereInput>>;
  NOT?: Maybe<Array<InviteScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expiresAt?: Maybe<DateTimeNullableFilter>;
  userId?: Maybe<StringFilter>;
};

export type InviteUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: Maybe<Role>;
};

export type EnumUserTypeFieldUpdateOperationsInput = {
  set?: Maybe<UserType>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type FormUpdateOneWithoutUserInput = {
  create?: Maybe<FormCreateWithoutUserInput>;
  connectOrCreate?: Maybe<FormCreateOrConnectWithoutUserInput>;
  upsert?: Maybe<FormUpsertWithoutUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormWhereUniqueInput>;
  update?: Maybe<FormUpdateWithoutUserInput>;
};

export type FormUpsertWithoutUserInput = {
  update: FormUpdateWithoutUserInput;
  create: FormCreateWithoutUserInput;
};

export type FormUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  json?: Maybe<StringFieldUpdateOperationsInput>;
  submitted?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submittedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  touchedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  Views?: Maybe<ViewUpdateManyWithoutFormInput>;
};

export type JobUpdateManyWithoutUserInput = {
  create?: Maybe<Array<JobCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<JobCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<JobUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<JobCreateManyUserInputEnvelope>;
  set?: Maybe<Array<JobWhereUniqueInput>>;
  disconnect?: Maybe<Array<JobWhereUniqueInput>>;
  delete?: Maybe<Array<JobWhereUniqueInput>>;
  connect?: Maybe<Array<JobWhereUniqueInput>>;
  update?: Maybe<Array<JobUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<JobUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<JobScalarWhereInput>>;
};

export type JobUpsertWithWhereUniqueWithoutUserInput = {
  where: JobWhereUniqueInput;
  update: JobUpdateWithoutUserInput;
  create: JobCreateWithoutUserInput;
};

export type JobUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  jobId?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  score?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postedDate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  jobDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  salary?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  state?: Maybe<NullableStringFieldUpdateOperationsInput>;
  apply_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  additionalQuestions?: Maybe<NullableStringFieldUpdateOperationsInput>;
  prefilledAdditionalQuestions?: Maybe<IntFieldUpdateOperationsInput>;
  searchOrigin?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  applicationTime?: Maybe<NullableStringFieldUpdateOperationsInput>;
  applicationStatus?: Maybe<NullableStringFieldUpdateOperationsInput>;
  applicationRequested?: Maybe<BoolFieldUpdateOperationsInput>;
  active?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type JobUpdateWithWhereUniqueWithoutUserInput = {
  where: JobWhereUniqueInput;
  data: JobUpdateWithoutUserInput;
};

export type JobUpdateManyWithWhereWithoutUserInput = {
  where: JobScalarWhereInput;
  data: JobUpdateManyMutationInput;
};

export type JobScalarWhereInput = {
  AND?: Maybe<Array<JobScalarWhereInput>>;
  OR?: Maybe<Array<JobScalarWhereInput>>;
  NOT?: Maybe<Array<JobScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  jobId?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  score?: Maybe<FloatNullableFilter>;
  title?: Maybe<StringNullableFilter>;
  company?: Maybe<StringNullableFilter>;
  postedDate?: Maybe<StringNullableFilter>;
  jobDescription?: Maybe<StringNullableFilter>;
  salary?: Maybe<FloatNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  apply_url?: Maybe<StringNullableFilter>;
  additionalQuestions?: Maybe<StringNullableFilter>;
  prefilledAdditionalQuestions?: Maybe<IntFilter>;
  searchOrigin?: Maybe<StringNullableFilter>;
  role?: Maybe<StringNullableFilter>;
  applicationTime?: Maybe<StringNullableFilter>;
  applicationStatus?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  applicationRequested?: Maybe<BoolFilter>;
  active?: Maybe<BoolFilter>;
};

export type JobUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  jobId?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  score?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postedDate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  jobDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  salary?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  state?: Maybe<NullableStringFieldUpdateOperationsInput>;
  apply_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  additionalQuestions?: Maybe<NullableStringFieldUpdateOperationsInput>;
  prefilledAdditionalQuestions?: Maybe<IntFieldUpdateOperationsInput>;
  searchOrigin?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  applicationTime?: Maybe<NullableStringFieldUpdateOperationsInput>;
  applicationStatus?: Maybe<NullableStringFieldUpdateOperationsInput>;
  applicationRequested?: Maybe<BoolFieldUpdateOperationsInput>;
  active?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type LoginLinkUpdateManyWithoutUserInput = {
  create?: Maybe<Array<LoginLinkCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<LoginLinkCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<LoginLinkUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<LoginLinkCreateManyUserInputEnvelope>;
  set?: Maybe<Array<LoginLinkWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoginLinkWhereUniqueInput>>;
  delete?: Maybe<Array<LoginLinkWhereUniqueInput>>;
  connect?: Maybe<Array<LoginLinkWhereUniqueInput>>;
  update?: Maybe<Array<LoginLinkUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<LoginLinkUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<LoginLinkScalarWhereInput>>;
};

export type LoginLinkUpsertWithWhereUniqueWithoutUserInput = {
  where: LoginLinkWhereUniqueInput;
  update: LoginLinkUpdateWithoutUserInput;
  create: LoginLinkCreateWithoutUserInput;
};

export type LoginLinkUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type LoginLinkUpdateWithWhereUniqueWithoutUserInput = {
  where: LoginLinkWhereUniqueInput;
  data: LoginLinkUpdateWithoutUserInput;
};

export type LoginLinkUpdateManyWithWhereWithoutUserInput = {
  where: LoginLinkScalarWhereInput;
  data: LoginLinkUpdateManyMutationInput;
};

export type LoginLinkScalarWhereInput = {
  AND?: Maybe<Array<LoginLinkScalarWhereInput>>;
  OR?: Maybe<Array<LoginLinkScalarWhereInput>>;
  NOT?: Maybe<Array<LoginLinkScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expiresAt?: Maybe<DateTimeNullableFilter>;
  userId?: Maybe<StringFilter>;
};

export type LoginLinkUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type NullableEnumIssuerFieldUpdateOperationsInput = {
  set?: Maybe<Issuer>;
};

export type EnumViewLevelFieldUpdateOperationsInput = {
  set?: Maybe<ViewLevel>;
};

export type ViewUpdateWithWhereUniqueWithoutFormInput = {
  where: ViewWhereUniqueInput;
  data: ViewUpdateWithoutFormInput;
};

export type ViewUpdateManyWithWhereWithoutFormInput = {
  where: ViewScalarWhereInput;
  data: ViewUpdateManyMutationInput;
};

export type ViewScalarWhereInput = {
  AND?: Maybe<Array<ViewScalarWhereInput>>;
  OR?: Maybe<Array<ViewScalarWhereInput>>;
  NOT?: Maybe<Array<ViewScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  formId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  ViewLevel?: Maybe<EnumViewLevelFilter>;
};

export type ViewUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ViewLevel?: Maybe<EnumViewLevelFieldUpdateOperationsInput>;
};

export type UserUpdateOneWithoutOwnFormInput = {
  create?: Maybe<UserCreateWithoutOwnFormInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnFormInput>;
  upsert?: Maybe<UserUpsertWithoutOwnFormInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutOwnFormInput>;
};

export type UserUpsertWithoutOwnFormInput = {
  update: UserUpdateWithoutOwnFormInput;
  create: UserCreateWithoutOwnFormInput;
};

export type UserUpdateWithoutOwnFormInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  registeredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  signedInAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  Invites?: Maybe<InviteUpdateManyWithoutUserInput>;
  Views?: Maybe<ViewUpdateManyWithoutUserInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  userType?: Maybe<EnumUserTypeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  removed?: Maybe<BoolFieldUpdateOperationsInput>;
  Jobs?: Maybe<JobUpdateManyWithoutUserInput>;
  applyId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sheetsUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sheetsStatusHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fetchingJobs?: Maybe<BoolFieldUpdateOperationsInput>;
  fetchingSheets?: Maybe<BoolFieldUpdateOperationsInput>;
  LoginLink?: Maybe<LoginLinkUpdateManyWithoutUserInput>;
  issuer?: Maybe<NullableEnumIssuerFieldUpdateOperationsInput>;
  generated?: Maybe<BoolFieldUpdateOperationsInput>;
  resume_check?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type ViewUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ViewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ViewCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ViewUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ViewCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ViewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ViewWhereUniqueInput>>;
  delete?: Maybe<Array<ViewWhereUniqueInput>>;
  connect?: Maybe<Array<ViewWhereUniqueInput>>;
  update?: Maybe<Array<ViewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ViewUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ViewScalarWhereInput>>;
};

export type ViewUpsertWithWhereUniqueWithoutUserInput = {
  where: ViewWhereUniqueInput;
  update: ViewUpdateWithoutUserInput;
  create: ViewCreateWithoutUserInput;
};

export type ViewUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  Form?: Maybe<FormUpdateOneRequiredWithoutViewsInput>;
  ViewLevel?: Maybe<EnumViewLevelFieldUpdateOperationsInput>;
};

export type FormUpdateOneRequiredWithoutViewsInput = {
  create?: Maybe<FormCreateWithoutViewsInput>;
  connectOrCreate?: Maybe<FormCreateOrConnectWithoutViewsInput>;
  upsert?: Maybe<FormUpsertWithoutViewsInput>;
  connect?: Maybe<FormWhereUniqueInput>;
  update?: Maybe<FormUpdateWithoutViewsInput>;
};

export type FormUpsertWithoutViewsInput = {
  update: FormUpdateWithoutViewsInput;
  create: FormCreateWithoutViewsInput;
};

export type FormUpdateWithoutViewsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  json?: Maybe<StringFieldUpdateOperationsInput>;
  submitted?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submittedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  touchedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  User?: Maybe<UserUpdateOneWithoutOwnFormInput>;
};

export type ViewUpdateWithWhereUniqueWithoutUserInput = {
  where: ViewWhereUniqueInput;
  data: ViewUpdateWithoutUserInput;
};

export type ViewUpdateManyWithWhereWithoutUserInput = {
  where: ViewScalarWhereInput;
  data: ViewUpdateManyMutationInput;
};

export type InviteCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  User: UserCreateNestedOneWithoutInvitesInput;
};

export type UserCreateNestedOneWithoutInvitesInput = {
  create?: Maybe<UserCreateWithoutInvitesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInvitesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutInvitesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  signedInAt?: Maybe<Scalars['DateTime']>;
  Views?: Maybe<ViewCreateNestedManyWithoutUserInput>;
  role?: Maybe<Role>;
  userType?: Maybe<UserType>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  removed?: Maybe<Scalars['Boolean']>;
  ownForm?: Maybe<FormCreateNestedOneWithoutUserInput>;
  Jobs?: Maybe<JobCreateNestedManyWithoutUserInput>;
  applyId?: Maybe<Scalars['Int']>;
  sheetsUrl?: Maybe<Scalars['String']>;
  sheetsStatusHash?: Maybe<Scalars['String']>;
  fetchingJobs?: Maybe<Scalars['Boolean']>;
  fetchingSheets?: Maybe<Scalars['Boolean']>;
  LoginLink?: Maybe<LoginLinkCreateNestedManyWithoutUserInput>;
  issuer?: Maybe<Issuer>;
  generated?: Maybe<Scalars['Boolean']>;
  resume_check?: Maybe<Scalars['Boolean']>;
};

export type UserCreateOrConnectWithoutInvitesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutInvitesInput;
};

export type InviteUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  User?: Maybe<UserUpdateOneRequiredWithoutInvitesInput>;
};

export type UserUpdateOneRequiredWithoutInvitesInput = {
  create?: Maybe<UserCreateWithoutInvitesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInvitesInput>;
  upsert?: Maybe<UserUpsertWithoutInvitesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutInvitesInput>;
};

export type UserUpsertWithoutInvitesInput = {
  update: UserUpdateWithoutInvitesInput;
  create: UserCreateWithoutInvitesInput;
};

export type UserUpdateWithoutInvitesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  registeredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  signedInAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  Views?: Maybe<ViewUpdateManyWithoutUserInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  userType?: Maybe<EnumUserTypeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  removed?: Maybe<BoolFieldUpdateOperationsInput>;
  ownForm?: Maybe<FormUpdateOneWithoutUserInput>;
  Jobs?: Maybe<JobUpdateManyWithoutUserInput>;
  applyId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sheetsUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sheetsStatusHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fetchingJobs?: Maybe<BoolFieldUpdateOperationsInput>;
  fetchingSheets?: Maybe<BoolFieldUpdateOperationsInput>;
  LoginLink?: Maybe<LoginLinkUpdateManyWithoutUserInput>;
  issuer?: Maybe<NullableEnumIssuerFieldUpdateOperationsInput>;
  generated?: Maybe<BoolFieldUpdateOperationsInput>;
  resume_check?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type JobUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  jobId?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  score?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postedDate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  jobDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  salary?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  state?: Maybe<NullableStringFieldUpdateOperationsInput>;
  apply_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  additionalQuestions?: Maybe<NullableStringFieldUpdateOperationsInput>;
  prefilledAdditionalQuestions?: Maybe<IntFieldUpdateOperationsInput>;
  searchOrigin?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  applicationTime?: Maybe<NullableStringFieldUpdateOperationsInput>;
  applicationStatus?: Maybe<NullableStringFieldUpdateOperationsInput>;
  User?: Maybe<UserUpdateOneWithoutJobsInput>;
  applicationRequested?: Maybe<BoolFieldUpdateOperationsInput>;
  active?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserUpdateOneWithoutJobsInput = {
  create?: Maybe<UserCreateWithoutJobsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutJobsInput>;
  upsert?: Maybe<UserUpsertWithoutJobsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutJobsInput>;
};

export type UserCreateWithoutJobsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  signedInAt?: Maybe<Scalars['DateTime']>;
  Invites?: Maybe<InviteCreateNestedManyWithoutUserInput>;
  Views?: Maybe<ViewCreateNestedManyWithoutUserInput>;
  role?: Maybe<Role>;
  userType?: Maybe<UserType>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  removed?: Maybe<Scalars['Boolean']>;
  ownForm?: Maybe<FormCreateNestedOneWithoutUserInput>;
  applyId?: Maybe<Scalars['Int']>;
  sheetsUrl?: Maybe<Scalars['String']>;
  sheetsStatusHash?: Maybe<Scalars['String']>;
  fetchingJobs?: Maybe<Scalars['Boolean']>;
  fetchingSheets?: Maybe<Scalars['Boolean']>;
  LoginLink?: Maybe<LoginLinkCreateNestedManyWithoutUserInput>;
  issuer?: Maybe<Issuer>;
  generated?: Maybe<Scalars['Boolean']>;
  resume_check?: Maybe<Scalars['Boolean']>;
};

export type UserCreateOrConnectWithoutJobsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutJobsInput;
};

export type UserUpsertWithoutJobsInput = {
  update: UserUpdateWithoutJobsInput;
  create: UserCreateWithoutJobsInput;
};

export type UserUpdateWithoutJobsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  registeredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  signedInAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  Invites?: Maybe<InviteUpdateManyWithoutUserInput>;
  Views?: Maybe<ViewUpdateManyWithoutUserInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  userType?: Maybe<EnumUserTypeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  removed?: Maybe<BoolFieldUpdateOperationsInput>;
  ownForm?: Maybe<FormUpdateOneWithoutUserInput>;
  applyId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sheetsUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sheetsStatusHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fetchingJobs?: Maybe<BoolFieldUpdateOperationsInput>;
  fetchingSheets?: Maybe<BoolFieldUpdateOperationsInput>;
  LoginLink?: Maybe<LoginLinkUpdateManyWithoutUserInput>;
  issuer?: Maybe<NullableEnumIssuerFieldUpdateOperationsInput>;
  generated?: Maybe<BoolFieldUpdateOperationsInput>;
  resume_check?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type JobCreateInput = {
  id?: Maybe<Scalars['String']>;
  jobId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  additionalQuestions?: Maybe<Scalars['String']>;
  prefilledAdditionalQuestions?: Maybe<Scalars['Int']>;
  searchOrigin?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  applicationTime?: Maybe<Scalars['String']>;
  applicationStatus?: Maybe<Scalars['String']>;
  User?: Maybe<UserCreateNestedOneWithoutJobsInput>;
  applicationRequested?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type UserCreateNestedOneWithoutJobsInput = {
  create?: Maybe<UserCreateWithoutJobsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutJobsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type NonUniqueInviteCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  assignAdmins?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<UserType>;
  issuer?: Maybe<Issuer>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type UserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  registeredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  signedInAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  Invites?: Maybe<InviteUpdateManyWithoutUserInput>;
  Views?: Maybe<ViewUpdateManyWithoutUserInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  userType?: Maybe<EnumUserTypeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  removed?: Maybe<BoolFieldUpdateOperationsInput>;
  ownForm?: Maybe<FormUpdateOneWithoutUserInput>;
  Jobs?: Maybe<JobUpdateManyWithoutUserInput>;
  applyId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sheetsUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sheetsStatusHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fetchingJobs?: Maybe<BoolFieldUpdateOperationsInput>;
  fetchingSheets?: Maybe<BoolFieldUpdateOperationsInput>;
  LoginLink?: Maybe<LoginLinkUpdateManyWithoutUserInput>;
  issuer?: Maybe<NullableEnumIssuerFieldUpdateOperationsInput>;
  generated?: Maybe<BoolFieldUpdateOperationsInput>;
  resume_check?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type ViewCreateInput = {
  id?: Maybe<Scalars['String']>;
  Form: FormCreateNestedOneWithoutViewsInput;
  User: UserCreateNestedOneWithoutViewsInput;
  ViewLevel?: Maybe<ViewLevel>;
};

export type ViewUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  Form?: Maybe<FormUpdateOneRequiredWithoutViewsInput>;
  User?: Maybe<UserUpdateOneRequiredWithoutViewsInput>;
  ViewLevel?: Maybe<EnumViewLevelFieldUpdateOperationsInput>;
};

export type ApplyMutationVariables = Exact<{
  jobId: Scalars['Int'];
  answers: Scalars['String'];
}>;


export type ApplyMutation = (
  { __typename?: 'Mutation' }
  & { apply: (
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'applicationRequested'>
  ) }
);

export type ChangePasswordWithTokenMutationVariables = Exact<{
  passwordResetToken: Scalars['String'];
  password: Scalars['String'];
}>;


export type ChangePasswordWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { changePasswordWithToken: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'active' | 'role' | 'userType' | 'formId'>
    & { ownForm?: Maybe<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'json' | 'submitted'>
    )> }
  ) }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'active' | 'role' | 'userType' | 'formId' | 'resume_check'>
    & { Views: Array<(
      { __typename?: 'View' }
      & Pick<View, 'id' | 'ViewLevel'>
      & { Form: (
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'submitted' | 'submittedAt' | 'json_name'>
        & { User?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'userType' | 'email' | 'registeredAt' | 'signedInAt'>
        )> }
      ) }
    )> }
  ) }
);

export type FormQueryVariables = Exact<{
  where: FormWhereUniqueInput;
}>;


export type FormQuery = (
  { __typename?: 'Query' }
  & { form?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'json' | 'submittedAt'>
    & { User?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'userType' | 'resume_check'>
    )> }
  )> }
);

export type UpsertFormMutationVariables = Exact<{
  where: FormWhereUniqueInput;
  create: FormCreateInput;
  update: FormUpdateInput;
}>;


export type UpsertFormMutation = (
  { __typename?: 'Mutation' }
  & { upsertForm: (
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'json' | 'touchedAt' | 'submittedAt'>
  ) }
);

export type FormsQueryVariables = Exact<{ [key: string]: never; }>;


export type FormsQuery = (
  { __typename?: 'Query' }
  & { forms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'touchedAt' | 'createdAt' | 'submittedAt'>
    & { User?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'role' | 'userType' | 'email' | 'registeredAt'>
    )>, Views: Array<(
      { __typename?: 'View' }
      & Pick<View, 'id' | 'ViewLevel'>
      & { User: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'role' | 'userType' | 'email'>
      ) }
    )> }
  )> }
);

export type CreateInviteMutationVariables = Exact<{
  data: InviteCreateInput;
}>;


export type CreateInviteMutation = (
  { __typename?: 'Mutation' }
  & { createInvite: (
    { __typename?: 'Invite' }
    & Pick<Invite, 'id'>
  ) }
);

export type UpsertInviteMutationVariables = Exact<{
  where: InviteWhereUniqueInput;
  create: InviteCreateInput;
  update: InviteUpdateInput;
}>;


export type UpsertInviteMutation = (
  { __typename?: 'Mutation' }
  & { upsertInvite: (
    { __typename?: 'Invite' }
    & Pick<Invite, 'id'>
  ) }
);

export type UpdateJobMutationVariables = Exact<{
  data: JobUpdateInput;
  where: JobWhereUniqueInput;
}>;


export type UpdateJobMutation = (
  { __typename?: 'Mutation' }
  & { updateJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'active'>
  )> }
);

export type JobsQueryVariables = Exact<{
  where: JobWhereInput;
}>;


export type JobsQuery = (
  { __typename?: 'Query' }
  & { jobs: Array<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'createdAt' | 'jobId' | 'company' | 'role' | 'salary' | 'postedDate' | 'title' | 'jobDescription' | 'city' | 'additionalQuestions' | 'applicationRequested' | 'active'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'active' | 'role' | 'userType' | 'formId'>
    & { ownForm?: Maybe<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'json' | 'submitted'>
    )> }
  ) }
);

export type LoginWithTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type LoginWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { loginWithToken: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'active' | 'role' | 'userType' | 'formId'>
    & { ownForm?: Maybe<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'json' | 'submitted'>
    )> }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MyViewsQueryVariables = Exact<{
  where: ViewWhereInput;
}>;


export type MyViewsQuery = (
  { __typename?: 'Query' }
  & { views: Array<(
    { __typename?: 'View' }
    & Pick<View, 'id' | 'ViewLevel'>
    & { Form: (
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'json'>
    ) }
  )> }
);

export type CreateNonUniqueInviteMutationVariables = Exact<{
  data: NonUniqueInviteCreateInput;
}>;


export type CreateNonUniqueInviteMutation = (
  { __typename?: 'Mutation' }
  & { createNonUniqueInvite: (
    { __typename?: 'NonUniqueInvite' }
    & Pick<NonUniqueInvite, 'id' | 'url' | 'expiresAt' | 'createdAt' | 'assignAdmins' | 'userType'>
  ) }
);

export type DeleteManyNonUniqueInvitesMutationVariables = Exact<{
  where?: Maybe<NonUniqueInviteWhereInput>;
}>;


export type DeleteManyNonUniqueInvitesMutation = (
  { __typename?: 'Mutation' }
  & { deleteManyNonUniqueInvite: (
    { __typename?: 'AffectedRowsOutput' }
    & Pick<AffectedRowsOutput, 'count'>
  ) }
);

export type NonUniqueInvitesQueryVariables = Exact<{
  where?: Maybe<NonUniqueInviteWhereInput>;
}>;


export type NonUniqueInvitesQuery = (
  { __typename?: 'Query' }
  & { nonUniqueInvites: Array<(
    { __typename?: 'NonUniqueInvite' }
    & Pick<NonUniqueInvite, 'id' | 'url' | 'expiresAt' | 'createdAt' | 'assignAdmins' | 'userType' | 'issuer'>
  )> }
);

export type PasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type PasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'passwordReset'>
);

export type RegisterMutationVariables = Exact<{
  inviteToken: Scalars['String'];
  password: Scalars['String'];
  email?: Maybe<Scalars['String']>;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'active' | 'role' | 'userType' | 'formId' | 'applyId'>
    & { ownForm?: Maybe<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'json' | 'submitted'>
    )> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'role' | 'userType' | 'active' | 'removed'>
  )> }
);

export type UsersQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'role' | 'userType' | 'active' | 'removed' | 'registeredAt'>
  )> }
);

export type DeleteManyViewMutationVariables = Exact<{
  where?: Maybe<ViewWhereInput>;
}>;


export type DeleteManyViewMutation = (
  { __typename?: 'Mutation' }
  & { deleteManyView: (
    { __typename?: 'AffectedRowsOutput' }
    & Pick<AffectedRowsOutput, 'count'>
  ) }
);

export type UpsertViewMutationVariables = Exact<{
  where: ViewWhereUniqueInput;
  update: ViewUpdateInput;
  create: ViewCreateInput;
}>;


export type UpsertViewMutation = (
  { __typename?: 'Mutation' }
  & { upsertView: (
    { __typename?: 'View' }
    & Pick<View, 'id'>
  ) }
);


export const ApplyDocument = gql`
    mutation Apply($jobId: Int!, $answers: String!) {
  apply(jobId: $jobId, answers: $answers) {
    id
    applicationRequested
  }
}
    `;
export type ApplyMutationFn = Apollo.MutationFunction<ApplyMutation, ApplyMutationVariables>;

/**
 * __useApplyMutation__
 *
 * To run a mutation, you first call `useApplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyMutation, { data, loading, error }] = useApplyMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useApplyMutation(baseOptions?: Apollo.MutationHookOptions<ApplyMutation, ApplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyMutation, ApplyMutationVariables>(ApplyDocument, options);
      }
export type ApplyMutationHookResult = ReturnType<typeof useApplyMutation>;
export type ApplyMutationResult = Apollo.MutationResult<ApplyMutation>;
export type ApplyMutationOptions = Apollo.BaseMutationOptions<ApplyMutation, ApplyMutationVariables>;
export const ChangePasswordWithTokenDocument = gql`
    mutation ChangePasswordWithToken($passwordResetToken: String!, $password: String!) {
  changePasswordWithToken(
    passwordResetToken: $passwordResetToken
    password: $password
  ) {
    id
    email
    active
    role
    userType
    formId
    ownForm {
      id
      json
      submitted
    }
  }
}
    `;
export type ChangePasswordWithTokenMutationFn = Apollo.MutationFunction<ChangePasswordWithTokenMutation, ChangePasswordWithTokenMutationVariables>;

/**
 * __useChangePasswordWithTokenMutation__
 *
 * To run a mutation, you first call `useChangePasswordWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordWithTokenMutation, { data, loading, error }] = useChangePasswordWithTokenMutation({
 *   variables: {
 *      passwordResetToken: // value for 'passwordResetToken'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordWithTokenMutation, ChangePasswordWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordWithTokenMutation, ChangePasswordWithTokenMutationVariables>(ChangePasswordWithTokenDocument, options);
      }
export type ChangePasswordWithTokenMutationHookResult = ReturnType<typeof useChangePasswordWithTokenMutation>;
export type ChangePasswordWithTokenMutationResult = Apollo.MutationResult<ChangePasswordWithTokenMutation>;
export type ChangePasswordWithTokenMutationOptions = Apollo.BaseMutationOptions<ChangePasswordWithTokenMutation, ChangePasswordWithTokenMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    email
    active
    role
    userType
    formId
    resume_check
    Views {
      id
      ViewLevel
      Form {
        id
        submitted
        submittedAt
        json_name
        User {
          id
          userType
          email
          registeredAt
          signedInAt
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const FormDocument = gql`
    query Form($where: FormWhereUniqueInput!) {
  form(where: $where) {
    id
    json
    submittedAt
    User {
      id
      userType
      resume_check
    }
  }
}
    `;

/**
 * __useFormQuery__
 *
 * To run a query within a React component, call `useFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFormQuery(baseOptions: Apollo.QueryHookOptions<FormQuery, FormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormQuery, FormQueryVariables>(FormDocument, options);
      }
export function useFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormQuery, FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormQuery, FormQueryVariables>(FormDocument, options);
        }
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormQueryResult = Apollo.QueryResult<FormQuery, FormQueryVariables>;
export const UpsertFormDocument = gql`
    mutation UpsertForm($where: FormWhereUniqueInput!, $create: FormCreateInput!, $update: FormUpdateInput!) {
  upsertForm(where: $where, create: $create, update: $update) {
    id
    json
    touchedAt
    submittedAt
  }
}
    `;
export type UpsertFormMutationFn = Apollo.MutationFunction<UpsertFormMutation, UpsertFormMutationVariables>;

/**
 * __useUpsertFormMutation__
 *
 * To run a mutation, you first call `useUpsertFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFormMutation, { data, loading, error }] = useUpsertFormMutation({
 *   variables: {
 *      where: // value for 'where'
 *      create: // value for 'create'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpsertFormMutation(baseOptions?: Apollo.MutationHookOptions<UpsertFormMutation, UpsertFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertFormMutation, UpsertFormMutationVariables>(UpsertFormDocument, options);
      }
export type UpsertFormMutationHookResult = ReturnType<typeof useUpsertFormMutation>;
export type UpsertFormMutationResult = Apollo.MutationResult<UpsertFormMutation>;
export type UpsertFormMutationOptions = Apollo.BaseMutationOptions<UpsertFormMutation, UpsertFormMutationVariables>;
export const FormsDocument = gql`
    query Forms {
  forms {
    id
    touchedAt
    createdAt
    submittedAt
    User {
      id
      role
      userType
      email
      registeredAt
    }
    Views {
      id
      User {
        id
        role
        userType
        email
      }
      ViewLevel
    }
  }
}
    `;

/**
 * __useFormsQuery__
 *
 * To run a query within a React component, call `useFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormsQuery(baseOptions?: Apollo.QueryHookOptions<FormsQuery, FormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
      }
export function useFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsQuery, FormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
        }
export type FormsQueryHookResult = ReturnType<typeof useFormsQuery>;
export type FormsLazyQueryHookResult = ReturnType<typeof useFormsLazyQuery>;
export type FormsQueryResult = Apollo.QueryResult<FormsQuery, FormsQueryVariables>;
export const CreateInviteDocument = gql`
    mutation CreateInvite($data: InviteCreateInput!) {
  createInvite(data: $data) {
    id
  }
}
    `;
export type CreateInviteMutationFn = Apollo.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>;

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(CreateInviteDocument, options);
      }
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type CreateInviteMutationResult = Apollo.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = Apollo.BaseMutationOptions<CreateInviteMutation, CreateInviteMutationVariables>;
export const UpsertInviteDocument = gql`
    mutation UpsertInvite($where: InviteWhereUniqueInput!, $create: InviteCreateInput!, $update: InviteUpdateInput!) {
  upsertInvite(where: $where, create: $create, update: $update) {
    id
  }
}
    `;
export type UpsertInviteMutationFn = Apollo.MutationFunction<UpsertInviteMutation, UpsertInviteMutationVariables>;

/**
 * __useUpsertInviteMutation__
 *
 * To run a mutation, you first call `useUpsertInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertInviteMutation, { data, loading, error }] = useUpsertInviteMutation({
 *   variables: {
 *      where: // value for 'where'
 *      create: // value for 'create'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpsertInviteMutation(baseOptions?: Apollo.MutationHookOptions<UpsertInviteMutation, UpsertInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertInviteMutation, UpsertInviteMutationVariables>(UpsertInviteDocument, options);
      }
export type UpsertInviteMutationHookResult = ReturnType<typeof useUpsertInviteMutation>;
export type UpsertInviteMutationResult = Apollo.MutationResult<UpsertInviteMutation>;
export type UpsertInviteMutationOptions = Apollo.BaseMutationOptions<UpsertInviteMutation, UpsertInviteMutationVariables>;
export const UpdateJobDocument = gql`
    mutation UpdateJob($data: JobUpdateInput!, $where: JobWhereUniqueInput!) {
  updateJob(where: $where, data: $data) {
    id
    active
  }
}
    `;
export type UpdateJobMutationFn = Apollo.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>;

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateJobMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobMutation, UpdateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, options);
      }
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>;
export type UpdateJobMutationResult = Apollo.MutationResult<UpdateJobMutation>;
export type UpdateJobMutationOptions = Apollo.BaseMutationOptions<UpdateJobMutation, UpdateJobMutationVariables>;
export const JobsDocument = gql`
    query Jobs($where: JobWhereInput!) {
  jobs(where: $where) {
    id
    createdAt
    jobId
    company
    role
    salary
    postedDate
    title
    jobDescription
    city
    additionalQuestions
    applicationRequested
    active
  }
}
    `;

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useJobsQuery(baseOptions: Apollo.QueryHookOptions<JobsQuery, JobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
      }
export function useJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<JobsQuery, JobsQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    id
    email
    active
    role
    userType
    formId
    ownForm {
      id
      json
      submitted
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginWithTokenDocument = gql`
    mutation LoginWithToken($token: String!) {
  loginWithToken(token: $token) {
    id
    email
    active
    role
    userType
    formId
    ownForm {
      id
      json
      submitted
    }
  }
}
    `;
export type LoginWithTokenMutationFn = Apollo.MutationFunction<LoginWithTokenMutation, LoginWithTokenMutationVariables>;

/**
 * __useLoginWithTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithTokenMutation, { data, loading, error }] = useLoginWithTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithTokenMutation, LoginWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithTokenMutation, LoginWithTokenMutationVariables>(LoginWithTokenDocument, options);
      }
export type LoginWithTokenMutationHookResult = ReturnType<typeof useLoginWithTokenMutation>;
export type LoginWithTokenMutationResult = Apollo.MutationResult<LoginWithTokenMutation>;
export type LoginWithTokenMutationOptions = Apollo.BaseMutationOptions<LoginWithTokenMutation, LoginWithTokenMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MyViewsDocument = gql`
    query MyViews($where: ViewWhereInput!) {
  views(where: $where) {
    id
    ViewLevel
    Form {
      id
      json
    }
  }
}
    `;

/**
 * __useMyViewsQuery__
 *
 * To run a query within a React component, call `useMyViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyViewsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMyViewsQuery(baseOptions: Apollo.QueryHookOptions<MyViewsQuery, MyViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyViewsQuery, MyViewsQueryVariables>(MyViewsDocument, options);
      }
export function useMyViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyViewsQuery, MyViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyViewsQuery, MyViewsQueryVariables>(MyViewsDocument, options);
        }
export type MyViewsQueryHookResult = ReturnType<typeof useMyViewsQuery>;
export type MyViewsLazyQueryHookResult = ReturnType<typeof useMyViewsLazyQuery>;
export type MyViewsQueryResult = Apollo.QueryResult<MyViewsQuery, MyViewsQueryVariables>;
export const CreateNonUniqueInviteDocument = gql`
    mutation CreateNonUniqueInvite($data: NonUniqueInviteCreateInput!) {
  createNonUniqueInvite(data: $data) {
    id
    url
    expiresAt
    createdAt
    assignAdmins
    userType
  }
}
    `;
export type CreateNonUniqueInviteMutationFn = Apollo.MutationFunction<CreateNonUniqueInviteMutation, CreateNonUniqueInviteMutationVariables>;

/**
 * __useCreateNonUniqueInviteMutation__
 *
 * To run a mutation, you first call `useCreateNonUniqueInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNonUniqueInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNonUniqueInviteMutation, { data, loading, error }] = useCreateNonUniqueInviteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNonUniqueInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNonUniqueInviteMutation, CreateNonUniqueInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNonUniqueInviteMutation, CreateNonUniqueInviteMutationVariables>(CreateNonUniqueInviteDocument, options);
      }
export type CreateNonUniqueInviteMutationHookResult = ReturnType<typeof useCreateNonUniqueInviteMutation>;
export type CreateNonUniqueInviteMutationResult = Apollo.MutationResult<CreateNonUniqueInviteMutation>;
export type CreateNonUniqueInviteMutationOptions = Apollo.BaseMutationOptions<CreateNonUniqueInviteMutation, CreateNonUniqueInviteMutationVariables>;
export const DeleteManyNonUniqueInvitesDocument = gql`
    mutation DeleteManyNonUniqueInvites($where: NonUniqueInviteWhereInput) {
  deleteManyNonUniqueInvite(where: $where) {
    count
  }
}
    `;
export type DeleteManyNonUniqueInvitesMutationFn = Apollo.MutationFunction<DeleteManyNonUniqueInvitesMutation, DeleteManyNonUniqueInvitesMutationVariables>;

/**
 * __useDeleteManyNonUniqueInvitesMutation__
 *
 * To run a mutation, you first call `useDeleteManyNonUniqueInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyNonUniqueInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyNonUniqueInvitesMutation, { data, loading, error }] = useDeleteManyNonUniqueInvitesMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyNonUniqueInvitesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyNonUniqueInvitesMutation, DeleteManyNonUniqueInvitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyNonUniqueInvitesMutation, DeleteManyNonUniqueInvitesMutationVariables>(DeleteManyNonUniqueInvitesDocument, options);
      }
export type DeleteManyNonUniqueInvitesMutationHookResult = ReturnType<typeof useDeleteManyNonUniqueInvitesMutation>;
export type DeleteManyNonUniqueInvitesMutationResult = Apollo.MutationResult<DeleteManyNonUniqueInvitesMutation>;
export type DeleteManyNonUniqueInvitesMutationOptions = Apollo.BaseMutationOptions<DeleteManyNonUniqueInvitesMutation, DeleteManyNonUniqueInvitesMutationVariables>;
export const NonUniqueInvitesDocument = gql`
    query NonUniqueInvites($where: NonUniqueInviteWhereInput) {
  nonUniqueInvites(where: $where) {
    id
    url
    expiresAt
    createdAt
    assignAdmins
    userType
    issuer
  }
}
    `;

/**
 * __useNonUniqueInvitesQuery__
 *
 * To run a query within a React component, call `useNonUniqueInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonUniqueInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonUniqueInvitesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNonUniqueInvitesQuery(baseOptions?: Apollo.QueryHookOptions<NonUniqueInvitesQuery, NonUniqueInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NonUniqueInvitesQuery, NonUniqueInvitesQueryVariables>(NonUniqueInvitesDocument, options);
      }
export function useNonUniqueInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NonUniqueInvitesQuery, NonUniqueInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NonUniqueInvitesQuery, NonUniqueInvitesQueryVariables>(NonUniqueInvitesDocument, options);
        }
export type NonUniqueInvitesQueryHookResult = ReturnType<typeof useNonUniqueInvitesQuery>;
export type NonUniqueInvitesLazyQueryHookResult = ReturnType<typeof useNonUniqueInvitesLazyQuery>;
export type NonUniqueInvitesQueryResult = Apollo.QueryResult<NonUniqueInvitesQuery, NonUniqueInvitesQueryVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($email: String!) {
  passwordReset(email: $email)
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, options);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($inviteToken: String!, $password: String!, $email: String) {
  register(inviteToken: $inviteToken, password: $password, email: $email) {
    id
    email
    active
    role
    userType
    formId
    applyId
    ownForm {
      id
      json
      submitted
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      inviteToken: // value for 'inviteToken'
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateUser(data: $data, where: $where) {
    id
    email
    role
    userType
    active
    removed
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UsersDocument = gql`
    query Users($where: UserWhereInput) {
  users(where: $where) {
    id
    email
    role
    userType
    active
    removed
    registeredAt
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const DeleteManyViewDocument = gql`
    mutation DeleteManyView($where: ViewWhereInput) {
  deleteManyView(where: $where) {
    count
  }
}
    `;
export type DeleteManyViewMutationFn = Apollo.MutationFunction<DeleteManyViewMutation, DeleteManyViewMutationVariables>;

/**
 * __useDeleteManyViewMutation__
 *
 * To run a mutation, you first call `useDeleteManyViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyViewMutation, { data, loading, error }] = useDeleteManyViewMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyViewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyViewMutation, DeleteManyViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyViewMutation, DeleteManyViewMutationVariables>(DeleteManyViewDocument, options);
      }
export type DeleteManyViewMutationHookResult = ReturnType<typeof useDeleteManyViewMutation>;
export type DeleteManyViewMutationResult = Apollo.MutationResult<DeleteManyViewMutation>;
export type DeleteManyViewMutationOptions = Apollo.BaseMutationOptions<DeleteManyViewMutation, DeleteManyViewMutationVariables>;
export const UpsertViewDocument = gql`
    mutation UpsertView($where: ViewWhereUniqueInput!, $update: ViewUpdateInput!, $create: ViewCreateInput!) {
  upsertView(where: $where, update: $update, create: $create) {
    id
  }
}
    `;
export type UpsertViewMutationFn = Apollo.MutationFunction<UpsertViewMutation, UpsertViewMutationVariables>;

/**
 * __useUpsertViewMutation__
 *
 * To run a mutation, you first call `useUpsertViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertViewMutation, { data, loading, error }] = useUpsertViewMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *      create: // value for 'create'
 *   },
 * });
 */
export function useUpsertViewMutation(baseOptions?: Apollo.MutationHookOptions<UpsertViewMutation, UpsertViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertViewMutation, UpsertViewMutationVariables>(UpsertViewDocument, options);
      }
export type UpsertViewMutationHookResult = ReturnType<typeof useUpsertViewMutation>;
export type UpsertViewMutationResult = Apollo.MutationResult<UpsertViewMutation>;
export type UpsertViewMutationOptions = Apollo.BaseMutationOptions<UpsertViewMutation, UpsertViewMutationVariables>;