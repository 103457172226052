import { UserType } from "generated/graphql";
import { useFormUser } from "./utils";

export const useFormFieldMapping = () => {
  const user = useFormUser();
  if (user.userType === UserType.Lite) {
    return formFieldMappingLite;
  }
  return formFieldMapping;
};

export const formFieldMapping = {
  firstName: { label: "First name" },
  lastName: { label: "Last name" },
  dateOfBirth: { label: "Date of birth" },
  personalEmail: { label: "Personal email address" },
  jobstepEmail: { label: "JobStep email address" },
  phoneNumber: { label: "Phone number" },
  streetAddress: { label: "Street address" },
  city: { label: "City" },
  zipCode: { label: "Zip code" },
  linkedIn: { label: "(Optional) LinkedIn" },
  angelist: { label: "(Optional) Angelist" },
  gitHub: { label: "(Optional) GitHub" },
  personalWebsite: { label: "(Optional) Personal website" },
  otherLink: { label: "(Optional) Other" },
  interestedRoles: {
    label: "What types of roles are you interested in?",
  },
  minimumAcceptableSalary: { label: "What is your minimum acceptable salary?" },
  preferredSalaryRangeMin: {
    label: "What is your preferred min. salary range?",
  },
  preferredSalaryRangeMax: {
    label: "What is your preferred max. salary range?",
  },
  employeeBenefits: {
    label: "What employee benefits MUST your next job provide?",
  },
  workLocation: {
    label: "In your next role, what work environment(s) are you considering?",
    values: {
      remote: "100% remote - I don’t want to go into an office",
      inPerson: "100% in-person",
      combination: "Combination - a mix of remote and in-person work",
    },
  },
  acceptableCommuteTime: {
    label: "What is your maximum acceptable commute time?",
  },
  willingToRelocate: {
    label: "Are you willing to relocate for a new job?",
    values: {
      "1": "Yes",
      "0": "No",
    },
  },
  preferredWorkLocations: { label: "(Optional) Preferred work locations" },
  workAuthorization: {
    label: "What work authorization do you have in the US?",
  },
  requireSponsorship: {
    label:
      "Will you now or in the future require sponsorship for employment visa status?",
  },
  workReferences: {
    label: "Work reference",
    fields: {
      fullName: "Full name",
      email: "Email address",
      phoneNumber: "Phone number",
      relationship: "Relationship",
      lengthOfRelationship: "Length of relationship",
    },
  },
  jobHistory: {
    label: "Work History",
    // titleField: "title",
    fields: {
      title: "Job title",
      employer: "Employer",
      isRemoteJob: {
        label: "This was a remote job",
        values: {
          true: "Yes",
          false: "No",
        },
      },
      city: "City",
      state: "State",
      isCurrentJob: {
        label: "This is my current job",
        values: {
          true: "Yes",
          false: "No",
        },
      },
      promotion: {
        label: "This was a promotion",
        values: {
          true: "Yes",
          false: "No",
        },
      },
      startingDate: "Starting date",
      endingDate: "Ending date",
      typicalDay: {
        label: "What was a typical day like at this job?",
      },
      primaryResponsibilities: "What were your primary responsibilities?",
      doingGoodJob: "How did you know you were doing a good job?",
      scopeOfWork:
        "Please share some numbers to describe the scope of your work.",
      awards: "(Optional) Did you earn any awards?",
      proudestMoments:
        "(Optional) What are your proudest moments from this job?",
      positiveImpactOnTeam:
        "Please list the ways you’ve had a positive impact on your team or employer",
      measureImpact:
        "(Optional) How did you or your boss measure your impact on the company?",
      uniqueAboutYou:
        "(Optional) What was unique about how you did your job compared to others?",
      reasonForLeavingJob: "Why did you decide to leave this job/role?",
      softwareTools:
        "Was there any special software or online tools you used to do your job successfully? How did you use them?",
    },
  },
  jobSearchingTime: {
    label: "How long have you been actively job-searching?",
  },
  jobApplicationCount: {
    label: "How many job applications have you submitted?",
  },
  firstRoundInterviewCount: {
    label: "How many first round interviews have you received?",
  },
  firstRoundLedToNextRoundCount: {
    label: "How many first round interviews have led to the next round?",
  },
  nextJobGoal: {
    label: "What do you want out of your next job?",
    multiline: true,
  },
  previousJobLiked: {
    label: "What did you like most about your previous jobs?",
    multiline: true,
  },
  nextEmployerValues: {
    label: "What are the values you want your next employer to have?",
    multiline: true,
  },
  careerGoals: {
    label: "What are your medium and/or long-term  career goals?",
    multiline: true,
  },
  topStrengths: { label: "What are your top strengths?", multiline: true },
  volunteerActivities: {
    multiline: true,
    label:
      "Are there any hobbies, interests, or volunteer activities that you would like to pursue professionally?",
  },
  languages: {
    label: "Language",
    // titleField: "language",
    fields: {
      language: "Language",
      profiencyLevel: {
        label: "Proficiency level",
        values: {
          elementary: "Elementary Proficiency",
          limitedWorking: "Limited Working Proficiency",
          professionalWorking: "Professional Working Proficiency",
          fullProfessional: "Full Professional Proficiency",
          nativeBilingual: "Native / Bilingual Proficiency",
        },
      },
    },
  },
  microsoftPowerpointDetails:
    "Please discuss a few situations where you’ve used this tool",
  microsoftExcelDetails:
    "Please discuss a few situations where you’ve used this tool",
  googleSuiteDetails:
    "Please discuss a few situations where you’ve used this tool",
  CRMsDetails: "Please discuss a few situations where you’ve used this tool",
  projectManagementToolsDetails:
    "Please discuss a few situations where you’ve used this tool",
  pointOfSaleSystemsDetails:
    "Please discuss a few situations where you’ve used this tool",
  inventoryManagementSystemsDetails:
    "Please discuss a few situations where you’ve used this tool",
  graphicSoftwareDetails:
    "Please discuss a few situations where you’ve used this tool",
  videoEditingSoftwareDetails:
    "Please discuss a few situations where you’ve used this tool",
  emailMarketingToolsDetails:
    "Please discuss a few situations where you’ve used this tool",
  digitalMarketingToolsDetails:
    "Please discuss a few situations where you’ve used this tool",
  helpdeskSoftwareDetails:
    "Please discuss a few situations where you’ve used this tool",
  codingLanguagesDetails:
    "Please discuss a few situations where you’ve used this tool",
  otherDetails: "Please discuss a few situations where you’ve used this tool",

  googleSuiteSelect: "Please list specific Google Suite you know",
  CRMsSelect:
    "Please list specific Customer Relationship Management Software (CRMs) you know",
  projectManagementToolsSelect:
    "Please list specific Project Management Tools you know",
  pointOfSaleSystemsSelect:
    "Please list specific Point-of-Sale Systems you know",
  inventoryManagementSystemsSelect:
    "Please list specific Inventory Management Systems you know",
  graphicSoftwareSelect:
    "Please list specific Graphic Design Software you know",
  videoEditingSoftwareSelect:
    "Please list specific Video Editing Software you know",
  emailMarketingToolsSelect:
    "Please list specific Email Marketing Tools you know",
  digitalMarketingToolsSelect:
    "Please list specific Digital Marketing Tools you know",
  helpdeskSoftwareSelect: "Please list specific Helpdesk Software you know",
  codingLanguagesSelect:
    "Please list specific Coding Languages/Frameworks you know",

  technicalSkills: {
    label: "Technical Skills",
    values: {
      microsoftPowerpoint: "Microsoft Powerpoint",
      microsoftExcel: "Microsoft Excel",
      googleSuite: "Google Suite",
      CRMs: "Customer Relationship Management Software (CRMs)",
      projectManagementTools: "Project Management Tools",
      pointOfSaleSystems: "Point-of-Sale Systems",
      inventoryManagementSystems: "Inventory Management Systems",
      graphicSoftware: "Graphic Design Software",
      videoEditingSoftware: "Video Editing Software",
      emailMarketingTools: "Email Marketing Tools",
      digitalMarketingTools: "Digital Marketing Tools",
      helpdeskSoftware: "Helpdesk Software",
      codingLanguages: "Coding Languages/Frameworks",
      other: "Other",
    },
  },
  educationHistory: {
    label: "Education History",
    // titleField: "institution",
    fields: {
      institution: "School or institution",
      fieldOfStudy: "Field of study",
      degreeOrCertification: "Degree or certification",
      city: "City",
      state: "State",
      country: "Country",
      startingDate: "Starting date",
      completedEducation: {
        label: "Have you completed this degree, course, or certification?",
        values: {
          "1": "Yes",
          "0": "No",
        },
      },
      endingDate: "Ending date",
    },
  },
  stories: {
    label: "Story",
    fields: {
      prompt: "S.O.A.R Prompt",
      situation:
        "Please tell me when this was, where this was, what your role was, and enough context so I understand the situation",
      obstacle:
        "What was the problem, challenge, obstacle, or goal facing you? Why was it important to address?",
      action:
        "How did you approach the obstacle? What steps did you take? How did you organize your work? Did you collaborate with others?",
      result:
        "What was the outcome of your effort? How did you know you succeeded? What did you learn?",
    },
  },
  agreementESignature: "E-signature",
  agreementEmailAddress: "Email address",
  agreementTodaysDate: "Date",
};

export const formFieldMappingLite = {
  ...formFieldMapping,
  jobHistory: {
    label: "Current company",
    fields: {
      title: "Job title",
      employer: "Employer",
    },
  },
};

export const personalInformationReviewLite = {
  blocks: [
    {
      title: "Personal Information",
      fields: ["firstName", "lastName", "dateOfBirth", "jobHistory"],
    },
    //{ title: "Current company", fields: ["jobHistory"] },
    {
      title: "Contact Information",
      fields: [
        "personalEmail",
        "phoneNumber",
        "streetAddress",
        "city",
        "zipCode",
      ],
    },
    {
      title: "Links",
      fields: [
        "linkedIn",
        "angelist",
        "gitHub",
        "personalWebsite",
        "otherLink",
      ],
    },
  ],
};

export const personalInformationReview = {
  blocks: [
    {
      title: "Personal Information",
      fields: ["firstName", "lastName", "dateOfBirth"],
    },
    {
      title: "Contact Information",
      fields: [
        "personalEmail",
        "phoneNumber",
        "streetAddress",
        "city",
        "zipCode",
      ],
    },
    {
      title: "Links",
      fields: [
        "linkedIn",
        "angelist",
        "gitHub",
        "personalWebsite",
        "otherLink",
      ],
    },
  ],
};

export const workPreferencesReview = {
  blocks: [
    {
      title: "Work Preferences",
      fields: [
        "interestedRoles",
        "minimumAcceptableSalary",
        "preferredSalaryRangeMin",
        "preferredSalaryRangeMax",
        "employeeBenefits",
        "workLocation",
        "acceptableCommuteTime",
        "willingToRelocate",
        "preferredWorkLocations",
      ],
    },
  ],
};

export const workAuthorizationsReview = {
  blocks: [
    {
      title: "Work Authorizations",
      fields: ["workAuthorization", "requireSponsorship"],
    },
  ],
};

export const workReferencesReview = {
  blocks: [
    {
      title: "",
      fields: ["workReferences"],
    },
  ],
};

export const jobSearchProgressReview = {
  blocks: [
    {
      title: "Job Search Progress",
      fields: [
        "jobSearchingTime",
        "jobApplicationCount",
        "firstRoundInterviewCount",
        "firstRoundLedToNextRoundCount",
      ],
    },
  ],
};

export const goalsReview = {
  blocks: [
    {
      title: "",
      fields: [
        "nextJobGoal",
        "previousJobLiked",
        "nextEmployerValues",
        "careerGoals",
        "topStrengths",
        "volunteerActivities",
      ],
    },
  ],
};

export const jobHistoryReview = {
  blocks: [
    {
      title: "",
      fields: ["jobHistory"],
    },
  ],
};

export const skillsEducationReview = {
  blocks: [
    {
      title: "",
      fields: ["languages"],
    },
    {
      title: "Technical Skills - Microsoft Excel",
      fields: ["microsoftExcelDetails"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("microsoftExcel"),
    },
    {
      title: "Technical Skills - Microsoft Powerpoint",
      fields: ["microsoftPowerpointDetails"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("microsoftPowerpoint"),
    },
    {
      title: "Technical Skills - Google Suite",
      fields: ["googleSuiteDetails", "googleSuiteSelect"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("googleSuite"),
    },
    {
      title:
        "Technical Skills - Customer Relationship Management Software (CRMs)",
      fields: ["CRMsDetails", "CRMsSelect"],
      hidden: (formFields) => !formFields.technicalSkills?.includes("CRMs"),
    },
    {
      title: "Technical Skills - Project Management Tools",
      fields: ["projectManagementToolsDetails", "projectManagementToolsSelect"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("projectManagementTools"),
    },
    {
      title: "Technical Skills - Point-of-Sale Systems",
      fields: ["pointOfSaleSystemsDetails", "pointOfSaleSystemsSelect"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("pointOfSaleSystems"),
    },
    {
      title: "Technical Skills - Inventory Management Systems",
      fields: [
        "inventoryManagementSystemsDetails",
        "inventoryManagementSystemsSelect",
      ],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("inventoryManagementSystems"),
    },
    {
      title: "Technical Skills - Graphic Design Software",
      fields: ["graphicSoftwareDetails", "graphicSoftwareSelect"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("graphicSoftware"),
    },
    {
      title: "Technical Skills - Video Editing Software",
      fields: ["videoEditingSoftwareDetails", "videoEditingSoftwareSelect"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("videoEditingSoftware"),
    },
    {
      title: "Technical Skills - Email Marketing Tools",
      fields: ["emailMarketingToolsDetails", "emailMarketingToolsSelect"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("emailMarketingTools"),
    },
    {
      title: "Technical Skills - Digital Marketing Tools",
      fields: ["digitalMarketingToolsDetails", "digitalMarketingToolsSelect"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("digitalMarketingTools"),
    },
    {
      title: "Technical Skills - Helpdesk Software",
      fields: ["helpdeskSoftwareDetails", "helpdeskSoftwareSelect"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("helpdeskSoftware"),
    },
    {
      title: "Technical Skills - Coding Languages/Frameworks",
      fields: ["codingLanguagesDetails", "codingLanguagesSelect"],
      hidden: (formFields) =>
        !formFields.technicalSkills?.includes("codingLanguages"),
    },
    {
      title: "Technical Skills - Other",
      fields: ["otherDetails"],
      hidden: (formFields) => !formFields.technicalSkills?.includes("other"),
    },
    {
      title: "",
      fields: ["educationHistory"],
      hidden: (formFields) => !formFields.educationHistory?.length,
    },
  ],
};

export const accomplishmentsReview = {
  blocks: [
    {
      title: "",
      fields: ["stories"],
    },
  ],
};

export const applicationAgreementReview = {
  blocks: [
    {
      title: "",
      fields: [
        "agreementESignature",
        "agreementEmailAddress",
        "agreementTodaysDate",
      ],
    },
  ],
};
